import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ListComponent, ListFromEventComponent } from 'src/app/elements/list-component';
import { IRefund, translateRefundStatus } from 'src/app/models/refund.model';
import { TableOptions } from 'src/app/models/type.definition';
import { RefundService } from 'src/app/providers';

@Component({
  selector: 'app-adminRefund',
  templateUrl: './adminRefund.component.html',
  styleUrls: ['./adminRefund.component.scss']
})
export class AdminRefundComponent extends ListComponent<IRefund>  {
  dataName = "Demande de remboursement";
  currentStatus: 'todo' | 'done' | 'canceled' = 'todo';
  tableOptions: TableOptions<IRefund>[] = [
    {
      title: "Organisation",
      class: "organisation_name",
      key: 'organisationName'
    },
    {
      title: "Évènement",
      class: "event_name",
      key: 'eventName'
    },
    {
      title: "Numéro de commande",
      class: "order_number",
      key: 'name'
    },
    {
      title: "Montant",
      class: "amount",
      key: 'amount',
    },
    {
      title: "Status",
      class: "status",
      key: 'translatedStatus',
      classKey: 'status'
    },
    {
      class: "more",
      if: data => data.status == 'todo',
      toggle: [
        {
          title: 'Valider',
          method: this.updateStatus('done'),
        },
        {
          title: 'Refuser',
          class: "red",
          method: this.updateStatus('canceled')
        }
      ]
    }
  ];

  constructor(protected provider: RefundService) {
    super()
  }

  protected formatElement(element: IRefund): IRefund {
    element.organisationName = element.organisationId.name;
    element.eventName = element.eventId.name;
    element.name = element.orderId?.orderNumber || 'Inconnue';
    element.amount = element.orderId.createdAt ? (element.orderId.totalPrice.sellingPrice / 100 + '€'): '';
    element.translatedStatus = translateRefundStatus(element.status);
    return element;
  }

  protected setGetParams(): void {
    this.getParams.filter.status = this.currentStatus;
    this.getParams.select = ['eventId', 'orderId', 'organisationId', 'status']
    this.getParams.populate = ['organisationId', 'eventId', 'orderId'];
  }

  changeStatus(status: 'todo' | 'done' | 'canceled'): void {
    this.currentStatus = status;
    this.getData();
  }

  public updateStatus(status: 'done' | 'canceled'): (data: IRefund, ref: this) => Promise<void> {
    return async (data: IRefund, ref = this) => {
      await ref.provider.update(data._id, { status }).toPromise();
      ref.data.splice(ref.data.findIndex(_ => _._id == data._id), 1);
    }
  }

}
