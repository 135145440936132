import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { CustomValidators } from 'src/app/etc/form-validators';
import { IEvent } from 'src/app/models/event.model';
import { ITicketType } from 'src/app/models/ticket.model';
import { DateInterval, GetParams } from 'src/app/models/type.definition';
import { CurrencyService, PromoCodeService, TicketTypeService } from 'src/app/providers';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { NotificationService } from 'src/app/services/notification.service';
import { GetEvent } from '../../../getEvent';

@Component({
  selector: 'app-event-ticketing-create-promo-code',
  templateUrl: './event-ticketing-create-promo-code.component.html',
  styleUrls: ['./event-ticketing-create-promo-code.component.scss'],
  providers: [GetEvent]
})
export class EventTicketingCreatePromoCodeComponent implements OnInit {
  private _subscription = new Subscription();
  public event: IEvent;
  public error: string;
  public loadingState$: Subject<void> = new Subject<void>();
  public currentUrl;
  public promoForm: FormGroup;
  public discount: string;
  public currencySymbol: string;
  public restrictTicket: boolean;
  public restrictDate: boolean;
  public selectedTickets: any[];
  public ticketsType: any[] = [];
  public dateOK: boolean;
  public dropDown = false;
  public checkedTicketTypes: string[] = [];
  public dates: { startDate: Date, endDate: Date } = { startDate: null, endDate: null };

  constructor(
    private formBuilder: FormBuilder,
    private getEvent: GetEvent,
    private router: Router,
    private promoCodeService: PromoCodeService,
    private notification: NotificationService,
    private errorHandler: ErrorHandlerService,
    private currencyService: CurrencyService,
    private ticketTypeService: TicketTypeService
  ) { }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  async ngOnInit() {
    this.discount = '%';
    this.currentUrl = this.router.url;
    const eventBody: GetParams<IEvent> = {
      select: ['name', 'ticketing']
    }
    this.event = await this.getEvent.get(eventBody);
    const ticketTypeBody: GetParams<ITicketType> = {
      filter: {
        eventId: this.event._id,
        isProduct: false
      },
      select: ['name', 'ticketTypeCategoryId'],
      populate: ['ticketTypeCategoryId'],
      page: 0
    };
    this.currencySymbol = this.event.ticketing.defaultCurrencyId?.symbol;
    this._subscription.add(this.ticketTypeService.getList(ticketTypeBody).subscribe(res => {
      this.ticketsType = res?.data || [];
      this.initForm();
    }))
  }

  initForm() {
    this.promoForm = this.formBuilder.group({
      name: new FormControl(null, Validators.required),
      code: new FormControl(null, Validators.required),
      counterScope: new FormControl('order', Validators.required),
      reduction: this.formBuilder.group({
        type: new FormControl('percentage', Validators.required),
        amount: new FormControl(null, CustomValidators.numberIsPositive)
      }),
      constraintTicketTypesIds: new FormControl([]),
      constraintBeginDate: new FormControl(null),
      constraintEndDate: new FormControl(null),
      constraintQuantity: new FormControl(null, CustomValidators.numberIsPositiveInteger)
    })
  }

  changeCounterScope(scope) {
    this.promoForm.get('counterScope').patchValue(scope)
  }

  createPromoCode() {
    const formData = {
      eventId: this.event._id,
      status: 'active',
      ...this.promoForm.value
    }
    if (formData.reduction.type === 'free') {
      formData.reduction.amount = 1;
    }
    formData.constraintTicketTypesIds = this.checkedTicketTypes;
    this.promoCodeService.create(formData).subscribe(result => {
      this.loadingState$.next();
      this.notification.newNotification({
        message: 'Code promo créé avec succès',
        state: 'success'
      });
      this.goBack();
    },
      (err: any) => {
        this.error = this.errorHandler.getError(err)
        this.loadingState$.next();
      });
  }

  changeDiscount(e) {
    const value = e.target.value;
    switch (value) {
      case 'percentage':
        this.discount = '%';
        break;
      case 'flat':
        this.discount = this.currencySymbol;
        break;
      case 'free':
        this.discount = 'free';
        break
    }
  }

  noAmount(): boolean {
    const amount = this.promoForm.value.reduction.amount;
    return !amount && this.discount !== 'free';
  }

  noDate(): boolean {
    return this.restrictDate && (!this.dates.startDate || !this.dates.endDate);
  }

  goBack(): void {
    this.router.navigate([`evenements/${this.event._id}/billetterie/codes-promos`])
  }

  displayRestrictedTicket(): void {
    this.restrictTicket = !this.restrictTicket;
  }

  displayRestrictedDate(): void {
    this.restrictDate = !this.restrictDate;
  }

  changeDates(dates) {
    const beginDate = dates.startDate;
    const endDate = dates.endDate;
    this.dates = dates;
    this.promoForm.patchValue({ constraintBeginDate: beginDate, constraintEndDate: endDate });
  }

  public checkTicket(id: string, value: boolean): void {
    const inArray = this.checkedTicketTypes.includes(id);
    if (value && !inArray) {
      this.checkedTicketTypes.push(id);
    }
    else if (!value && inArray) {
      this.checkedTicketTypes = this.checkedTicketTypes.filter(_ => _ !== id);
    }
  }

  public ticketChecked(id: string): boolean {
    return this.checkedTicketTypes.includes(id) || null;
  }

  public getTicketsName(): string {
    const names = this.ticketsType.filter(_ => this.checkedTicketTypes.includes(_.id));
    if (!names.length) {
      return 'Séléctionner des tickets';
    }
    else {
      const fullString = names.map(_ => _.name).join(', ');
      return fullString.length < 80 ? fullString : `${fullString.substring(0, 77)} ...`
    }
  }

  @HostListener('document:click') clickDoc(): void {
    this.dropDown = false;
  }
}
