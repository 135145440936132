<div class="form_item image" [ngClass]="{ 'has_image' : file}" [ngStyle]="file && dimensions"
    (mouseover)="displayDisabledText = true" (mouseleave)="displayDisabledText = false">
    <ng-container *ngIf="loading">
        <div class="image_loader"></div>
    </ng-container>
    <ng-container *ngIf="!loading">
        <app-popup *ngIf="error" title="Une erreur est survenue" message="{{error}}" popupOpened="true"
            (backEvent)="error = null"></app-popup>
        <ng-container *ngIf="!file && !base64">
            <input *ngIf="!disabled" type="file" accept=".png, .jpg, .jpeg, .mp4" (change)="uploadFile($event)">
            <label>
                <i>
                    <svg role="img" title="add" class="icon-add">
                        <use href="assets/images/sprite.svg#icon-add"></use>
                    </svg>
                </i>
                {{text}}
            </label>
            <span class="details">
                max {{limitSizeMo}} mo<br />
                <span *ngFor="let format of acceptedFormat; index as i">
                    .{{format}}{{acceptedFormat.length > i + 1 ? ', ' : ''}}
                </span>
            </span>
        </ng-container>
        <div *ngIf="(file || base64) && !disabled" class="image_show" [ngStyle]="dimensions">
            <button class="remove_image" (click)="deleteFile()">
                <svg role="img" title="close" class="icon-close">
                    <use href="assets/images/sprite.svg#icon-close"></use>
                </svg>
            </button>
            <image-cropper *ngIf="!isVideo" [imageBase64]="base64" [imageChangedEvent]="imageChangedEvent"
                (imageCropped)="imageCropped($event)" (loadImageFailed)="loadImageFailed()"
                [maintainAspectRatio]="false" [cropperStaticHeight]="cropperStaticHeight"
                [cropperStaticWidth]="cropperStaticWidth" [--cropper-outline-color]="'rgba(125,205,255,1)'">
            </image-cropper>
            <video 
                *ngIf="isVideo && videoLocalUrl"
                autoplay="true"
                loop="true"
                [muted]="true"
            >
                <source [src]="videoLocalUrl"
                        type="video/mp4">
            </video>
        </div>
        <img *ngIf="(file || base64) && disabled && !isVideo" [ngStyle]="dimensions" [src]="base64" alt="Image du ticket">
        <div *ngIf="disabled && displayDisabledText" class="disabled_text">
            {{disabledText}}
        </div>
    </ng-container>
</div>