export const environment = {
	production: true,
	name:'preprod',
	apiUrl: 'https://api.v4.oandb.eu',
	instagramRedirectUrl: 'https://api.instagram.com/oauth/authorize?client_id=249389607147863&redirect_uri=https://oandb-organizer.advency.eu/tableau-de-bord/&scope=user_profile,user_media&response_type=code',
	instagramClientId: '249389607147863',
	instagramSecret: '1def1a0bc6570c26c26f01f380db5710',
	socketUrl: 'https://socket.v3.oandb.eu',
	widgetUrl: 'https://billetterie.v3.oandb.eu',
	eventSiteBaseUrl: "https://site.v3.oandb.eu/",
	widgetBaseUrl: "https://billetterie.v3.oandb.eu/",
	captchaKey: "6LcCaP4kAAAAAIsH4sRDqTwvy2lXQKMpD8K3F-DA"
};
