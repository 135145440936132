import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Pages
import { InitPage } from './pages/init-page/init-page';
import { NotFoundPage } from './pages/not-found/not-found';
import { NotAuthorizedPage } from './pages/not-authorized/not-authorized';
import { LogInComponent } from './pages/auth-related/log-in/log-in.component';
import { SignUpComponent } from './pages/auth-related/sign-up/sign-up.component';
import { PasswordForgottenComponent } from './pages/auth-related/password-forgotten/password-forgotten.component';
import { SeeMoreComponent } from './pages/auth-related/see-more/see-more.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { AuthGuard } from './etc/authGuard';
import { AdminGuard } from './etc/admin-guard';
import { TeamComponent } from './pages/team/team.component';
import { EventsComponent } from './pages/events/events.component';
import { GeneralSettingsComponent } from './pages/settings/general-settings/general-settings.component';
import { RibibanSettingsComponent } from './pages/settings/ribiban-settings/ribiban-settings.component';
import { PaymentAccountComponent } from './pages/settings/payment-account/payment-account.component';
import { SeatingPlanComponent } from './pages/settings/seating-plan/seating-plan.component';
import { SeatingPlanCreateComponent } from './pages/settings/seating-plan/seating-plan-create/seating-plan-create.component';
import { AccountingSettingsComponent } from './pages/settings/accounting-settings/accounting-settings.component';
import { OrganisationsComponent } from './pages/organisations/organisations.component';
import { CreateOrganisationComponent } from './pages/organisations/create-organisation/create-organisation.component';
import { MyAccountComponent } from './pages/my-account/my-account.component';
import { CreateEventComponent } from './pages/events/create-event/create-event.component';
import { AddMemberComponent } from './pages/team/add-member/add-member.component';
import { EventGeneralComponent } from './pages/events/event-general/event-general.component';
import { EventSettingsComponent } from './pages/events/event-settings/event-settings.component';
import { EventFormsComponent } from './pages/events/event-forms/event-forms.component';
import { EventFormsCreateComponent } from './pages/events/event-forms/event-forms-create/event-forms-create.component';
import { EventCampaignComponent } from './pages/events/event-campaign/event-campaign.component';
import { EventCampaignCreateComponent } from './pages/events/event-campaign/event-campaign-create/event-campaign-create.component';
import { EventMiniSiteComponent } from './pages/events/event-mini-site/event-mini-site.component';
import { EventOrdersComponent } from './pages/events/event-orders/event-orders.component';
import { EventOrdersCreateComponent } from './pages/events/event-orders/event-orders-create/event-orders-create.component';
import { EventOrdersDetailComponent } from './pages/events/event-orders/event-orders-detail/event-orders-detail.component';
import { EventParticipantsComponent } from './pages/events/event-participants/event-participants.component';
import { EventParticipantsDetailComponent } from './pages/events/event-participants/event-participants-detail/event-participants-detail.component';
import { EventCounterComponent } from './pages/events/event-counter/event-counter.component';
import { EventGuichetComponent } from './pages/events/event-guichet/event-guichet.component';
import { EventAccessControlOperatorComponent } from './pages/events/access-control/event-access-control-operator/event-access-control-operator.component';
import { EventAccessControlOperatorCreateComponent } from './pages/events/access-control/event-access-control-operator/event-access-control-operator-create/event-access-control-operator-create.component';
import { EventAccessControlComponent } from './pages/events/access-control/event-access-control/event-access-control.component';
import { EventAccessControlCreateComponent } from './pages/events/access-control/event-access-control/event-access-control-create/event-access-control-create.component';
import { EventTicketingComponent } from './pages/events/ticketing/event-ticketing/event-ticketing.component';
import { EventTicketingEticketComponent } from './pages/events/ticketing/event-ticketing-eticket/event-ticketing-eticket.component';
import { EventTicketingBadgeComponent } from './pages/events/ticketing/event-ticketing-badge/event-ticketing-badge.component';
import { EventTicketingEticketUniqueComponent } from './pages/events/ticketing/event-ticketing-eticket-unique/event-ticketing-eticket-unique.component';
import { EventTicketingSettingsComponent } from './pages/events/ticketing/event-ticketing-settings/event-ticketing-settings.component';
import { EventTicketingPromoCodeComponent } from './pages/events/ticketing/event-ticketing-promo-code/event-ticketing-promo-code.component';
import { EventTicketingInvitationComponent } from './pages/events/ticketing/event-ticketing-invitation/event-ticketing-invitation.component';
import { EventTicketingInvitationCreateComponent } from './pages/events/ticketing/event-ticketing-invitation/event-ticketing-invitation-create/event-ticketing-invitation-create.component';
import { EventTicketingSessionComponent } from './pages/events/ticketing/event-ticketing-session/event-ticketing-session.component';
import { EventTicketingCreateCategoryComponent } from './pages/events/ticketing/event-ticketing/event-ticketing-create-category/event-ticketing-create-category.component';
import { EventTicketingCreateRateComponent } from './pages/events/ticketing/event-ticketing/event-ticketing-create-rate/event-ticketing-create-rate.component';
import { EventTicketingCreatePromoCodeComponent } from './pages/events/ticketing/event-ticketing-promo-code/event-ticketing-create-promo-code/event-ticketing-create-promo-code.component';
import { EventTicketingGeneratePromoCodeComponent } from './pages/events/ticketing/event-ticketing-promo-code/event-ticketing-generate-promo-code/event-ticketing-generate-promo-code.component';
import { EventTicketingUpdatePromoCodeComponent } from './pages/events/ticketing/event-ticketing-promo-code/event-ticketing-update-promo-code/event-ticketing-update-promo-code.component';
import { EventTicketingSessionCreateComponent } from './pages/events/ticketing/event-ticketing-session/event-ticketing-session-create/event-ticketing-session-create.component';
import { EventTicketingSeatingPlanComponent } from './pages/events/ticketing/event-ticketing-seating-plan/event-ticketing-seating-plan.component';
import { EventWidgetComponent } from './pages/events/widget/event-widget.component';
import { EventWidgetCreateComponent } from './pages/events/widget/event-widget-create/event-widget-create.component';
import { EventCreateMiniSiteComponent } from './pages/events/event-mini-site/event-create-mini-site/event-create-mini-site.component';
import { TermsAndConditionComponent } from './pages/events/create-event/terms-and-condition/terms-and-condition.component';
import { AdminDashboardComponent } from './pages/adminDashboard/adminDashboard.component';
import { AdminEventsComponent } from './pages/adminEvents/adminEvents.component';
import { AdminOrdersComponent } from './pages/adminOrders/adminOrders.component';
import { AdminTicketsComponent } from './pages/adminTickets/adminTickets.component';
import { AdminOrganizationsComponent } from './pages/adminOrganizations/adminOrganizations.component';
import { NotAdminGuard } from './etc/not-admin-guard';
import { WaitingListComponent } from './pages/events/event-participants/waiting-list/waiting-list.component';
import { AdminRefundComponent } from './pages/adminRefund/adminRefund.component';
import { EventTicketingAddonComponent } from './pages/events/ticketing/event-ticketing-addon/event-ticketing-addon.component';
import { EventTicketingCreateAddonComponent } from './pages/events/ticketing/event-ticketing-addon/event-ticketing-create-addon/event-ticketing-create-addon.component';
import { EventTicketingProductComponent } from './pages/events/ticketing/event-ticketing-product/event-ticketing-product.component';
import { EventTicketingProductCreateComponent } from './pages/events/ticketing/event-ticketing-product/event-ticketing-product-create/event-ticketing-product-create.component';
import { EventTicketingProductCreateCategoryComponent } from './pages/events/ticketing/event-ticketing-product/event-ticketing-product-create-category/event-ticketing-product-create-category.component';
import { EventTicketingSecurityComponent } from './pages/events/ticketing/event-ticketing-security/event-ticketing-security.component';
import { EventExclusiveContentComponent } from './pages/events/event-exclusive-content/event-exclusive-content.component';
import { EventCreateExclusiveContentComponent } from './pages/events/event-exclusive-content/event-create-exclusive-content/event-create-exclusive-content.component';


const routes: Routes = [
  /** AUTH */
  { path: '', component: InitPage },
  { path: 'connexion', component: LogInComponent },
  { path: 'redirect/:url', component: LogInComponent },
  { path: 'inscription', component: SignUpComponent },
  { path: 'mot-de-passe-oublie', component: PasswordForgottenComponent },
  { path: 'informations', component: SeeMoreComponent },

  /** ORGANISATION */
  { path: 'organisations', component: OrganisationsComponent, canActivate: [AuthGuard, NotAdminGuard] },
  { path: 'organisations/creer', component: CreateOrganisationComponent, canActivate: [AuthGuard, NotAdminGuard] },

  /** MAIN */
  { path: 'tableau-de-bord', component: DashboardComponent, canActivate: [AuthGuard, NotAdminGuard] },
  { path: 'admin', component: AdminDashboardComponent, canActivate: [AuthGuard, AdminGuard] },
  { path: 'demandes-de-remboursements', component: AdminRefundComponent, canActivate: [AuthGuard, AdminGuard] },
  { path: 'data', redirectTo: 'data/organizations' },
  { path: 'data/events', component: AdminEventsComponent, canActivate: [AuthGuard, AdminGuard] },
  { path: 'data/organizations', component: AdminOrganizationsComponent, canActivate: [AuthGuard, AdminGuard] },
  { path: 'data/orders', component: AdminOrdersComponent, canActivate: [AuthGuard, AdminGuard] },
  { path: 'data/tickets', component: AdminTicketsComponent, canActivate: [AuthGuard, AdminGuard] },

  // Events
  { path: 'evenements', component: EventsComponent, canActivate: [AuthGuard, NotAdminGuard] },
  { path: 'evenements/creer-un-evenement', component: CreateEventComponent, canActivate: [AuthGuard, NotAdminGuard] },
  { path: 'evenements/termes-et-conditions', component: TermsAndConditionComponent, canActivate: [AuthGuard, NotAdminGuard] },
  { path: 'evenements/:id/general', component: EventGeneralComponent, canActivate: [AuthGuard] },
  { path: 'evenements/:id/parametres', component: EventSettingsComponent, canActivate: [AuthGuard] },
  { path: 'evenements/:id/billetterie', redirectTo: 'evenements/:id/billetterie/ticket' },
  { path: 'evenements/:id/billetterie/ticket', component: EventTicketingComponent, canActivate: [AuthGuard] },
  { path: 'evenements/:id/billetterie/ticket/creer-ticket', component: EventTicketingCreateRateComponent, canActivate: [AuthGuard] },
  { path: 'evenements/:id/billetterie/ticket/creer-categorie', component: EventTicketingCreateCategoryComponent, canActivate: [AuthGuard] },
  { path: 'evenements/:id/billetterie/ticket/editer-ticket/:ticketId', component: EventTicketingCreateRateComponent, canActivate: [AuthGuard] },
  { path: 'evenements/:id/billetterie/ticket/editer-categorie/:categoryId', component: EventTicketingCreateCategoryComponent, canActivate: [AuthGuard] },
  { path: 'evenements/:id/billetterie/badges', component: EventTicketingBadgeComponent, canActivate: [AuthGuard] },
  { path: 'evenements/:id/billetterie/e-tickets', component: EventTicketingEticketComponent, canActivate: [AuthGuard] },
  { path: 'evenements/:id/billetterie/ticket/e-tickets/:ticketId', component: EventTicketingEticketUniqueComponent, canActivate: [AuthGuard] },
  { path: 'evenements/:id/billetterie/parametres', component: EventTicketingSettingsComponent, canActivate: [AuthGuard] },
  { path: 'evenements/:id/billetterie/invitations', redirectTo: '/evenements/:id/commandes/creer?orderType=invitation' },
  { path: 'evenements/:id/billetterie/codes-promos', component: EventTicketingPromoCodeComponent, canActivate: [AuthGuard] },
  { path: 'evenements/:id/billetterie/codes-promos/creer', component: EventTicketingCreatePromoCodeComponent, canActivate: [AuthGuard] },
  { path: 'evenements/:id/billetterie/codes-promos/generer', component: EventTicketingGeneratePromoCodeComponent, canActivate: [AuthGuard] },
  { path: 'evenements/:id/billetterie/codes-promos/editer/:promoCodeId', component: EventTicketingUpdatePromoCodeComponent, canActivate: [AuthGuard] },
  { path: 'evenements/:id/billetterie/sessions', component: EventTicketingSessionComponent, canActivate: [AuthGuard] },
  { path: 'evenements/:id/billetterie/sessions/creer', component: EventTicketingSessionCreateComponent, canActivate: [AuthGuard] },
  { path: 'evenements/:id/billetterie/sessions/editer/:sessionId', component: EventTicketingSessionCreateComponent, canActivate: [AuthGuard] },
  { path: 'evenements/:id/billetterie/addons', component: EventTicketingAddonComponent, canActivate: [AuthGuard] },
  { path: 'evenements/:id/billetterie/addons/creer', component: EventTicketingCreateAddonComponent, canActivate: [AuthGuard] },
  { path: 'evenements/:id/billetterie/addons/editer/:addonId', component: EventTicketingCreateAddonComponent, canActivate: [AuthGuard] },
  { path: 'evenements/:id/billetterie/produits', component: EventTicketingProductComponent, canActivate: [AuthGuard] },
  { path: 'evenements/:id/billetterie/produits/creer', component: EventTicketingProductCreateComponent, canActivate: [AuthGuard] },
  { path: 'evenements/:id/billetterie/produits/creer-categorie', component: EventTicketingProductCreateCategoryComponent, canActivate: [AuthGuard] },
  { path: 'evenements/:id/billetterie/produits/editer-produit/:ticketId', component: EventTicketingProductCreateComponent, canActivate: [AuthGuard] },
  { path: 'evenements/:id/billetterie/produits/editer-categorie/:categoryId', component: EventTicketingProductCreateCategoryComponent, canActivate: [AuthGuard] },
  { path: 'evenements/:id/billetterie/securite', component: EventTicketingSecurityComponent, canActivate: [AuthGuard] },
  { path: 'evenements/:id/billetterie/widgets', component: EventWidgetComponent, canActivate: [AuthGuard] },
  { path: 'evenements/:id/billetterie/widgets/creer', component: EventWidgetCreateComponent, canActivate: [AuthGuard] },
  { path: 'evenements/:id/billetterie/widgets/editer/:widgetId', component: EventWidgetCreateComponent, canActivate: [AuthGuard] },
  { path: 'evenements/:id/billetterie/seating-plan', component: EventTicketingSeatingPlanComponent, canActivate: [AuthGuard] },
  { path: 'evenements/:id/billetterie/sessions/seating-plan/:sessionId', component: EventTicketingSeatingPlanComponent, canActivate: [AuthGuard] },
  { path: 'evenements/:id/formulaires', component: EventFormsComponent, canActivate: [AuthGuard] },
  { path: 'evenements/:id/formulaires/creer', component: EventFormsCreateComponent, canActivate: [AuthGuard] },
  { path: 'evenements/:id/formulaires/editer/:formId', component: EventFormsCreateComponent, canActivate: [AuthGuard] },
  { path: 'evenements/:id/campagnes', component: EventCampaignComponent, canActivate: [AuthGuard] },
  { path: 'evenements/:id/campagnes/creer', component: EventCampaignCreateComponent, canActivate: [AuthGuard] },
  { path: 'evenements/:id/mini-sites', component: EventMiniSiteComponent, canActivate: [AuthGuard] },
  { path: 'evenements/:id/mini-sites/creer', component: EventCreateMiniSiteComponent, canActivate: [AuthGuard] },
  { path: 'evenements/:id/mini-sites/editer/:siteId', component: EventCreateMiniSiteComponent, canActivate: [AuthGuard] },
  { path: 'evenements/:id/exclusive-content', component: EventExclusiveContentComponent, canActivate: [AuthGuard] },
  { path: 'evenements/:id/exclusive-content/creer', component: EventCreateExclusiveContentComponent, canActivate: [AuthGuard] },
  { path: 'evenements/:id/commandes', data: {isProduct: false}, component: EventOrdersComponent, canActivate: [AuthGuard] },
  { path: 'evenements/:id/commandes/produits', data: {isProduct: true}, component: EventOrdersComponent, canActivate: [AuthGuard] },
  { path: 'evenements/:id/commandes/creer', component: EventOrdersCreateComponent, canActivate: [AuthGuard] },
  { path: 'evenements/:id/commandes/:orderId', component: EventOrdersDetailComponent, canActivate: [AuthGuard] },
  { path: 'evenements/:id/participants', redirectTo: 'evenements/:id/participants/tous' },
  { path: 'evenements/:id/participants/tous', component: EventParticipantsComponent, canActivate: [AuthGuard] },
  { path: 'evenements/:id/participants/liste-d-attente', component: WaitingListComponent, canActivate: [AuthGuard] },
  { path: 'evenements/:id/participants/tous/details/:participantId', component: EventParticipantsDetailComponent, canActivate: [AuthGuard] },
  { path: 'evenements/:id/guichet', component: EventGuichetComponent, canActivate: [AuthGuard] },
  { path: 'evenements/:id/controle-d-acces', redirectTo: 'evenements/:id/controle-d-acces/listes' },
  { path: 'evenements/:id/controle-d-acces/listes', component: EventAccessControlComponent, canActivate: [AuthGuard] },
  { path: 'evenements/:id/controle-d-acces/listes/creer', component: EventAccessControlCreateComponent, canActivate: [AuthGuard] },
  { path: 'evenements/:id/controle-d-acces/listes/editer/:listId', component: EventAccessControlCreateComponent, canActivate: [AuthGuard] },
  // { path: 'evenements/:id/controle-d-acces/operateurs', component: EventAccessControlOperatorComponent, canActivate: [AuthGuard] },
  // { path: 'evenements/:id/controle-d-acces/operateurs/creer', component: EventAccessControlOperatorCreateComponent, canActivate: [AuthGuard] },
  // { path: 'evenements/:id/controle-d-acces/operateurs/editer/:operatorId', component: EventAccessControlOperatorCreateComponent, canActivate: [AuthGuard] },

  // Team
  { path: 'equipe', component: TeamComponent, canActivate: [AuthGuard, NotAdminGuard] },
  { path: 'equipe/ajouter-un-membre', component: AddMemberComponent, canActivate: [AuthGuard, NotAdminGuard] },
  { path: 'equipe/ajouter-un-membre/:id', component: AddMemberComponent, canActivate: [AuthGuard, NotAdminGuard] },

  // Settings
  { path: 'parametres', redirectTo: 'parametres/general' },
  { path: 'parametres/general', component: GeneralSettingsComponent, canActivate: [AuthGuard, NotAdminGuard] },
  // { path: 'parametres/rib-iban', component: RibibanSettingsComponent, canActivate: [AuthGuard] },
  { path: 'parametres/payment-account', component: PaymentAccountComponent, canActivate: [AuthGuard, NotAdminGuard] },
  { path: 'parametres/seating-plan', component: SeatingPlanComponent, canActivate: [AuthGuard, NotAdminGuard] }, // Organisator
  { path: 'seating-plan', component: SeatingPlanComponent, canActivate: [AuthGuard, AdminGuard] }, // Admin
  { path: 'parametres/seating-plan/edit', component: SeatingPlanCreateComponent, canActivate: [AuthGuard, NotAdminGuard] }, // Organisator
  { path: 'seating-plan/edit', component: SeatingPlanCreateComponent, canActivate: [AuthGuard, AdminGuard] }, // Admin
  { path: 'parametres/seating-plan/edit/:chartId', component: SeatingPlanCreateComponent, canActivate: [AuthGuard, NotAdminGuard] }, // Organisator
  { path: 'seating-plan/edit/:chartId', component: SeatingPlanCreateComponent, canActivate: [AuthGuard, AdminGuard] }, // Admin
  { path: 'parametres/comptabilite', component: AccountingSettingsComponent, canActivate: [AuthGuard, NotAdminGuard] },
  { path: 'reddition', component: AccountingSettingsComponent, canActivate: [AuthGuard, AdminGuard] },


  /** OTHER  */
  { path: 'mon-compte', component: MyAccountComponent, canActivate: [AuthGuard] },
  { path: "404", component: NotFoundPage, canActivate: [AuthGuard] },
  { path: "403", component: NotAuthorizedPage, canActivate: [AuthGuard] },
  { path: 'en', redirectTo: '' },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
