import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Provider } from '../provider';
import { APICreateResponse, APIResponse, GetParams } from 'src/app/models/type.definition';
import { IOrder } from 'src/app/models/order.model';
import { HTTPTools } from 'src/app/etc/http-tools';

@Injectable()
export class OrderService extends Provider<IOrder> {

  protected url = "events/orders";

  public markAsPaid(id: string): Observable<APIResponse> {
    return this.httpClient.post<APIResponse>(this.getUrl(`pay/${id}`), {});
  }

  public paymentAccountCreate(): Observable<APIResponse> {
    return this.httpClient.get<APIResponse>(this.getUrl(`payment-account/create`));
  }

  public paymentAccountGetLink(): Observable<APIResponse> {
    return this.httpClient.get<APIResponse>(this.getUrl(`payment-account/get-link`));
  }

  public paymentAccountCheck(): Observable<APIResponse> {
    return this.httpClient.get<APIResponse>(this.getUrl(`payment-account/check`));
  }
  public resendAnOrderConfirmation(id: string): Observable<APIResponse> {
    return this.httpClient.get<APIResponse>(this.getUrl(`resend-an-order-confirmation/${id}`));
  }

  public resendAnOrderInvoice(id: string): Observable<APIResponse> {
    return this.httpClient.put<APIResponse>(this.getUrl(`send-invoice/${id}`), {});
  }


  public downloadOne(id: string): Observable<APIResponse> {
    return this.httpClient.get<APIResponse>(this.getUrl(`download-one-order/${id}`));
  }
  public askRefund(id: string): Observable<APIResponse> {
    return this.httpClient.get<APIResponse>(this.getUrl(`ask-refund/${id}`));
  }
  public cancel(id: string): Observable<APIResponse> {
    return this.httpClient.get<APIResponse>(this.getUrl(`cancel/${id}`));
  }
  public exportCSV(eventId: string, body?: GetParams<IOrder>): Observable<Blob> {
    return this.httpClient.get(this.getUrl(`list/export/${eventId}`, body), { responseType: 'blob' });
  }
  public streamOrder(id: string, token: string, type: 'orderId' | 'ticketId' = 'orderId', template: 'ticket' | 'badge' = 'ticket'): Observable<Blob> {
    HTTPTools.setNextContentType('application/pdf');
    return this.httpClient.get(this.getUrl(`stream/${type}/${id}/${token}`, {pdfTemplate: template}), { responseType: 'blob' });
  }

}
