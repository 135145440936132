import { TicketTypeCategoryService, TicketTypeService } from "../providers";
import { IAddon } from "./addon.model";
import { IControlList } from "./control-list";
import { EditableData, EditableEventData } from "./editable-data";
import { IEvent } from "./event.model";
import { IFormField } from "./form.model";
import { IMedia } from "./media.model";
import { IOrder } from "./order.model";
import { IOrganisation } from "./organisation.model";
import { ITax } from "./tax.model";
import User from "./user.model";

export interface ITicketType extends EditableEventData {
  name?: string;
  price?: number;
  stringPrice?: string;
  sold?: number;
  isVisible?: boolean;
  taxId?: ITax;
  description?: string;
  available?: string;
  order?: number;
  disabled?: boolean;
  scheduled?: {
    isScheduled?: boolean,
    startDate?: Date,
    endDate?: Date,
    timezone?: string,
    type?: 'afterPriceSoldOut' | 'atDate',
    ticketTypeIdToRelay?: string
  };
  quota?: number;
  totalQuota?: string;
  stock?: number;
  tickets?: {
    minimumSelectable?: number,
    maximumSelectable?: number
  };
  inTicketOffice?: boolean;
  helpText?: string;
  orderMailText?: string;
  ticketVisual?: {
    overriden?: boolean,
    text?: string,
    imageId?: IMedia,
    image?: Blob
  };
  badgeVisual?: {
    overriden?: boolean,
    text?: string;
    footerText?: string;
    imageId?: IMedia;
    mainColor?: string;
    formFieldId?: string;
    formField?: IFormField;
    badgeImage?: Blob;
  }
  security?: {
		isResalable?: boolean,
		priceHasLimit?: boolean,
		maxPricePercent?: number,
		minPricePercent?: number,
	};
  ticketTypeCategoryId?: ITicketTypeCategory;
  flag?: {
    hotTicketType?: boolean
  };
  advancePayment?: {
    enabled?: boolean,
    splitType?: 'flat' | 'percentage',
    operations?:
    {
      onOrdering?: boolean,
      daysBeforeEvent?: number,
      daysAfterEvent?: number,
      amount?: number
    }[]
  };
  isProduct?: boolean;
  showCountDown?: boolean;
  showRemainingPlace?: boolean;
  statistics?: EntityStatistics;
  stocks?: EntityStocks
}
export interface EntityStocks {
  quota?: number;
  sold?: number;
  inDraft?: number;
  inStock?: number;
}
export interface EntityStatistics {
  drafts?: number;
  invitations?: number;
  participants?: number;
  orders?: number;
  costPrice?: number;
  sellingPrice?: number;
  commission?: number;
}

export interface ITicketTypeCategory extends EditableEventData {
  name?: string;
  quota?: number;
  totalQuota?: string;
  order?: number;
  stock?: number;
  isVisible?: boolean;
  sold?: number;
  ticketTypes?: ITicketType[];
  isProduct?: boolean;
  seatsIoCategoryId?: string;
  seatsIoColor?: string;
}

export interface ITicket extends EditableData {
  orderId?: IOrder;
  name?: string;
  orderNumber?: string;
  email?: string;
  orderDate?: string;
  origin?: string;
  translatedOrigin?: TranslatedOriginStatus;
  status?: 'draft' | 'abandoned' | 'paid' | 'notPaid' | 'partiallyPaid' | 'paymentRefused' | 'canceled';
  expire?: Date;
  price?: {
    sellingPrice?: number,
    costPrice?: number,
    commission?: {
      flat?: number,
      percentage?: number,
      calculated?: number
    }
  };
  firstName?: string;
  lastName?: string;
  fullName?: string;
  form?: {
    id?: string,
    fields?: {
      formFieldId?: string,
      label?: string,
      values?: string | number | string[] | {name: string, url: string}
    }[]
  }[];
  ticketTypeCategoryId?: ITicketTypeCategory;
  categoryName?: string;
  formFields?: IFormField[];
  ticketTypeId?: ITicketType;
  ticketName?: string;
  ticketNumber?: string;
  qrCode?: string;
  invitationCode?: string;
  addons?: {
    price: number,
    taxId: string,
    addonId: IAddon,
    name: string;
  }[];
  scans?: {
    scanWay?: 'in' | 'out',
    controlListId?: IControlList
    dateScan?: Date,
    referer?: 'mobile' | 'web',
    device?: string,
    operatorId?: User,
    comment?: string
  }[];
  "scans.controlList"?: string
  "scans.operator"?: string
  sessionId?: string;
  seat?: string;
  eventId?: IEvent;
  eventName?: string;
  organisationId?: IOrganisation;
  organisationName?: string;
}


type TranslatedOriginStatus = 'Web' | 'Guichet' | 'Invitation' | 'Impression de stock';

/**
 * Method to translate the order origin
 * @param value
 */
export function translatedOrigin(value: string): TranslatedOriginStatus {
  switch (value) {
    case 'web': return 'Web';
    case 'ticketOffice': return 'Guichet';
    case 'invitation': return 'Invitation';
    case 'stockImpression': return 'Impression de stock';
    default: return 'Web'
  }
}

export function updateOrCreateEmptyCategory(event: IEvent, tickets: ITicketType[] = [], key = 'ticketTypeCategories'): void {
  const noCat = event[key].find(_ => _ == null);
  if (noCat) {
    noCat.ticketTypes.push(...tickets);
  }
  else {
    event[key].push({
      name: "Sans Catégorie",
      eventId: event._id,
      id: null,
      _id: null,
      quota: null,
      order: event[key].length + 100,
      ticketTypes: tickets
    })
  }
}

/**
 * Method to reset order of ticket and categories in case of trouble
 * @param event
 * @param catService
 * @param rateService
 */
export async function resetOrders(
  event: IEvent,
  catService: TicketTypeCategoryService,
  rateService: TicketTypeService): Promise<void> {
  for (let i = 0; i < event.ticketTypeCategories.length; i++) {
    if (event.ticketTypeCategories[i]._id) {
      await catService.update(event.ticketTypeCategories[i]._id, { order: i }).toPromise();
      event.ticketTypeCategories[i].order = i;
    }
    for (let j = 0; j < event.ticketTypeCategories[i].ticketTypes?.length || 0; j++) {
      await rateService.update(event.ticketTypeCategories[i].ticketTypes[j]._id, { order: j, eventId: event }).toPromise();
      event.ticketTypeCategories[i].ticketTypes[j].order = j;
    }
  }
}