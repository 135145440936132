import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { EventService, EventCategoryService, CurrencyService, TaxService, CountryService, UserService, OrganisationService } from 'src/app/providers';
import { GetEvent } from '../getEvent';
import { Subscription, Subject, Observable } from 'rxjs';
import { FormGroup, FormBuilder, FormControl, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { UpdateService } from 'src/app/services/update.service';
import { NotificationService } from 'src/app/services/notification.service';
import { APIError, GetParams } from 'src/app/models/type.definition';
import { IEvent, IEventPublishableStatus } from 'src/app/models/event.model';
import { moreAnimation } from 'src/app/animations/animations';
import { Router } from '@angular/router';
import { ICountry } from 'src/app/models/country.model';
import { first } from 'rxjs/operators';
import User from 'src/app/models/user.model';
import { StorageService } from 'src/app/services/storage.service';
import { IEventCategory } from 'src/app/models/event-category.model';

@Component({
  selector: 'app-event-settings',
  templateUrl: './event-settings.component.html',
  styleUrls: ['./event-settings.component.scss'],
  providers: [GetEvent],
  animations: [moreAnimation]
})
export class EventSettingsComponent implements OnInit, OnDestroy {
  private _subscription = new Subscription();
  public deletePanelOpen = false;
  public manageToggleOpen: boolean;
  public manageToggle = {
    ref: this,
    links: [
      {
        title: 'Publier',
        method: this.publish
      },
      {
        title: 'Supprimer',
        class: "red",
        method: () => {
          this.deletePanelOpen = true;
          this.manageToggleOpen = false;
        }
      },
    ]
  }
  public eventPublishableStatus: IEventPublishableStatus;
  public selectedStartDate: string;
  public selectedStartTime: string[];
  public selectedEndDate: string;
  public selectedEndTime: string[];
  public loadingState$: Subject<void> = new Subject<void>();
  public eventsCount: number;
  public eventForm: FormGroup;
  public categories: IEventCategory[];
  public addressEntered = true;
  public addressChanged: boolean;
  public parentCategories: any[];
  public childCategories: any[];
  public selectedCategory: string;
  public tags: string[] = [];
  public countries: ICountry[];
  public currencies: any;
  public usersOrganisation: any;
  public taxes: any;
  public dataLoading = { value: false }
  public error: string
  public event: IEvent;
  public isInSearch = false;
  public currentUser: any;
  public isAdmin = false;
  public multipleDate = false;
  public previousDates: { startDate: Date, endDate: Date }
  public currentType: 'presential' | 'remote';
  public fullLoader = false;
  public canEditCommissionType: boolean;
  public displayCommissionDisabledText = false;

  constructor(
    private eventService: EventService,
    private formBuilder: FormBuilder,
    private categoriesService: EventCategoryService,
    private currencyService: CurrencyService,
    private userService: UserService,
    private taxService: TaxService,
    private errorHandler: ErrorHandlerService,
    private getEvent: GetEvent,
    private update: UpdateService,
    private countryService: CountryService,
    private notification: NotificationService,
    private router: Router,
    private notificationService: NotificationService,
    private storage: StorageService,
    private organisationService: OrganisationService,
  ) { }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  public subscribeTo<T>(
    observable: Observable<T>,
    callback: (value: T) => void | Promise<void>,
    onError = (error: APIError) => console.error(error)): void {
    this._subscription.add(observable.subscribe(callback, onError));
  }

  async ngOnInit() {
    const getParams: GetParams<IEvent> = {
      populate: ['categoriesIds', 'place.countryId']
    }

    this.currentUser = await new Promise(res => this.subscribeTo(this.userService.me$, user => {
      if (user) {
        res(user);
      }
    }));

    if (this.currentUser.isSuperAdmin) {
      this.isAdmin = true
      const getUsersParams: GetParams<User> = {
        filter: {
          organisationId: this.storage.getItem('organisationId'),
          roles: 'organizer',
        },
        select: ['firstName', 'lastName', 'email', 'roles'],
      }
      this._subscription.add(this.userService.getList(getUsersParams).subscribe((result: any) => {
        this.usersOrganisation = result?.data
      }));
    }

    this.event = await this.getEvent.get(getParams);
    const stats = await this.eventService.getEventStats(this.event._id).toPromise();
    this.canEditCommissionType = !stats.sellingPrice;
    this.currentType = this.event.eventType || 'presential';
    this._subscription.add(this.eventService.getList().subscribe(result => {
      this.eventsCount = result?.data?.length || 0;
    }));
    this._subscription.add(this.currencyService.getList().subscribe((result: any) => {
      this.currencies = result?.data
    }));

    this._subscription.add(this.taxService.getList().subscribe((result: any) => {
      this.taxes = result?.data
    }));
    this._subscription.add(this.countryService.getList().subscribe((result: any) => {
      this.countries = result?.data
    }));
    this._subscription.add(this.categoriesService.getList({ perPage: 500 }).subscribe(categories => {
      this.categories = categories?.data || [];
      this.parentCategories = this.categories.filter(_ => !_.parentId);
      this.initForm();
    }));
    this.eventPublishableStatus = await this.eventService.canBePublished(this.event._id).toPromise();
    if (this.event.status !== 'draft') {
      this.manageToggle = null
    }
    else if (!this.eventPublishableStatus.rateCreated) {
      this.manageToggle.links[0].class = 'publish_disabled';
    }
    this.isInSearch = this.event.isInSearch;
    this.multipleDate = this.event.dates.type === 'interval' ? false : true;
  }


  initForm(): void {
    this.eventForm = this.formBuilder.group({
      // image: new FormControl(this.event.image),
      name: new FormControl({value: this.event.name, disabled: this.isPublishedNftEvent()}, Validators.required),
      categoryId: new FormControl(this.event.categoriesIds[0]._id),
      categoryId2: new FormControl(this.event.categoriesIds[1]._id),
      bookType: new FormControl(this.event.bookType || 'ticketing'),
      tags: new FormControl([]),
      place: this.formBuilder.group({
        name: new FormControl(this.event.place[0]?.name),
        address: new FormControl(this.event.place[0]?.address),
        address2: new FormControl(this.event.place[0]?.address2),
        zipcode: new FormControl(this.event.place[0]?.zipcode),
        city: new FormControl(this.event.place[0]?.city),
        countryId: new FormControl(this.event.place[0]?.countryId?._id)
      }),
      dates: this.formBuilder.group({
        type: new FormControl(this.event.dates.type, Validators.required),
        sessionsIds: new FormControl(this.event.dates.sessionsIds),
        startDate: new FormControl(this.event.dates.startDate, this.setOrMultiple),
        endDate: new FormControl(this.event.dates.endDate),
        timeZone: this.event.dates.timeZone
      }),
      description: new FormControl(this.event.description),
      ticketing: this.formBuilder.group({
        defaultCurrencyId: new FormControl(this.event.ticketing?.defaultCurrencyId?._id || null, Validators.required),
        taxId: new FormControl(this.event.ticketing?.taxId?._id, Validators.required),
      }),
      commissionIncreased: new FormControl(this.event.commissionIncreased),
      langCode: this.event.langCode || 'FR',
      isInSearch: new FormControl(this.event.isInSearch),
      eventLink: new FormControl(this.event.eventLink || ''),
      eventType: new FormControl(this.currentType),
      authorId: new FormControl(this.event.authorId || null)
    });

    // Render required description if booktype is nft
    if (this.event.bookType === 'nft') {
      this.eventForm.get('description').setValidators(Validators.required);
    }

    this.setCategories();
  }


  setCategories(): void {
    this.parentCategories = this.categories.filter(_ => !_.parentId);
    const categoryId = this.event.categoriesIds[0]._id
    this.eventForm.patchValue({ categoryId })
    this.selectCategory(categoryId);
    if (this.event.categoriesIds[1]) {
      this.eventForm.patchValue({ categoryId2: this.event.categoriesIds[1]._id });
    }
  }

  setOrMultiple(control: AbstractControl): ValidationErrors | null {
    const sessions = control.parent?.value.type == "sessions"
    return control.value || sessions ? null : { error: "Date needed" };
  }

  setDescriptionValue(value) {
    this.eventForm.patchValue({ description: value });
  }

  selectCategory(parentCategory: string): void {
    this.selectedCategory = parentCategory;

    this.childCategories = this.categories.filter(_ => {
      return _.parentId?._id === parentCategory
    });
    this.eventForm.patchValue({ categoryId2: this.childCategories[0]?._id })
  }

  changeDates(dates) {
    const startDate = dates.startDate;
    const endDate = dates.endDate;
    const datesForm = this.eventForm.get('dates') as FormGroup;
    datesForm.patchValue({ startDate, endDate });
  }


  uploadFile(file) {
    this.eventForm.patchValue({ file });
  }

  checkTypeDate(): void {
    this.multipleDate = !this.multipleDate
    const type = this.multipleDate ? "sessions" : "interval";
    const dates = this.eventForm.get('dates') as FormGroup;
    dates.patchValue({ type });
    if (this.multipleDate) {
      this.previousDates = {
        startDate: dates.value.startDate,
        endDate: dates.value.endDate
      }
      dates.patchValue({ startDate: null, endDate: null });
    }
    else if (this.previousDates) {
      dates.patchValue(this.previousDates);
    }
  }

  addTag(tag: any) {
    this.tags.push(tag.value);
    tag.value = "";
  }

  deleteTag(tag: string) {
    this.tags = this.tags.filter(_ => _ !== tag);
  }

  changeIsInSearch() {
    this.isInSearch = !this.isInSearch
    this.eventForm.patchValue({ isInSearch: this.isInSearch })
  }


  selectType(type: 'presential' | 'remote'): void {
    this.currentType = type;
  }

  updateEvent(): void {
    const formData = this.eventForm.value;
    if (!Array.isArray(formData.place)) {
      formData.place = [formData.place];
    }
    formData.categoriesIds = [formData.categoryId, formData.categoryId2];
    formData.ticketing = {
      ...this.event.ticketing,
      defaultCurrencyId: formData.ticketing.defaultCurrencyId,
      taxId: formData.ticketing.taxId
    }
    if (this.currentType == 'presential') {
      formData.eventLink = null
    }
    else if (this.currentType == 'remote') {
      formData.place = []
    }
    this.eventService.update(this.event._id, formData).subscribe(result => {
      this.loadingState$.next();
      this.event = this.update.object(this.event, result);
      this.eventService.selected$.next(this.event);
      this.notification.newNotification({
        message: 'Événement mis à jour avec succès',
        state: 'success'
      })
    },
      (err: any) => {
        this.error = this.errorHandler.getError(err)
        this.loadingState$.next();
      }
    )
  }

  setEnteredAddress(e) {
    if (!this.addressChanged) {
      if (e !== null) {
        this.addressChanged = true;
      }
      else {
        return
      }
    }
    else {
      this.addressEntered = e !== null;
    }
    const place = this.eventForm.get('place');
    const country = this.countries.find(_ => _.name == e?.country);
    place.patchValue({
      address: e?.name,
      zipcode: e?.postcode,
      city: e?.city,
      countryId: country?._id
    });
  }

  selectTimeZone(timeZone: string) {
    const dates = this.eventForm.get('dates') as FormGroup;
    dates.patchValue({ timeZone });
  }

  isPublishedNftEvent() {
    return (this.event.bookType === 'nft' && this.event.status === 'published') ? true : false;
  }

  @HostListener('document:click')
  documentClick() {
    this.manageToggleOpen && (this.manageToggleOpen = false);
  }

  displayManageToggle() {
    setTimeout(() => this.manageToggleOpen = !this.manageToggleOpen);
  }

  publish(ref = this): void {
    ref.displayManageToggle();
    ref.fullLoader = true;
    ref.eventService.update(ref.event._id, { status: 'published' }).subscribe(res => {
      ref.fullLoader = false;
      ref.event.status = "published";
      ref.event.translateStatus = "Publié";
      ref.notificationService.newNotification({ message: "Événement publié avec succès", state: "success" })
    })
  }

  deleteOk() {
    this.eventService.delete(this.event._id).pipe(first()).subscribe(() => {
      this.notification.newNotification({ message: `Événement supprimé(e)`, state: 'success' });
      this.router.navigateByUrl('evenements');
    }, err => {
      this.notification.newNotification({ message: `Une erreur est survenue, veuillez réessayer ultérieurement`, state: 'error' })
    });
  }


}
