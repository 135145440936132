<section id="form_page" class="container loading_container" [ngClass]="{ 'loaded' : mainForm }">
    <div class="loading_content">
        <div id="page_header">
            <div id="page_header_left">
                <h1>Addon</h1>
            </div>
        </div>
        <div *ngIf="loaded" id="form_wrapper">
            <h2>{{data ? 'Éditer' : 'Créer'}} un addon</h2>

            <form *ngIf="mainForm" [formGroup]="mainForm">
                <div class="form_item">
                    <label for="name">Nom de l'addon</label>
                    <input maxlength="40" id="name" type="text" formControlName="name">
                    <div class="description">40 caractères maximum</div>
                </div>
                <div class="form_item">
                    <label for="description">Description</label>
                    <textarea name="description" id="description" formControlName="description"></textarea>
                </div>

                <div class="form_cols">
                    <div class="form_item half">
                        <label for="price">Prix</label>
                        <input id="price" type="number" formControlName="price">
                    </div>
                    <div class="form_item half">
                        <label for="tax">TVA spécifique à l'addon</label>
                        <div class="input_wrap select">
                            <select id="tax" formControlName="taxId">
                                <option value="null" hidden></option>
                                <option *ngFor="let tax of taxes" [value]="tax._id">
                                    {{tax.value / 100 + "%"}}</option>
                            </select>
                            <svg role="img" title="chevron" class="icon-chevron">
                                <use href="assets/images/sprite.svg#icon-chevron"></use>
                            </svg>
                        </div>
                    </div>
                </div>
                <div class="form_cols">
                    <div formGroupName="stocks" class="form_item half">
                        <label for="name">Quota global maximum</label>
                        <input id="name" type="number" formControlName="quota">
                    </div>
                    <div *ngIf="event && ![undefined, null].includes(data?.stocks.inStock)" class="form_item half">
                        <label for="name">Stock Actuel</label>
                        <input id="name" type="text" disabled [value]="data?.stocks.inStock">
                    </div>
                </div>
                <div>
                    <h3 for="ticket_type">Tarifs concernés</h3>

                    <p *ngIf="!ticketTypes?.length" class="form_item error">Il semble que vous n’ayez pas encore créé de
                        tarifs,
                        <a routerLink="../../ticket/creer-ticket">créez-en un !</a>
                    </p>
                    <div class="form_item" *ngIf="ticketTypes?.length">
                        <div class="input_wrap select multiple">
                            <span class="select_multiple_toggle"
                                (click)="dropDown = !dropDown; $event.stopPropagation()">
                                {{getTicketsName()}}
                            </span>
                            <svg role="img" title="chevron" class="icon-chevron">
                                <use href="assets/images/sprite.svg#icon-chevron"></use>
                            </svg>
                            <div class="dropdown multiple" *ngIf="dropDown" (click)="$event.stopPropagation()">
                                <ul>
                                    <li *ngFor="let ticket of ticketTypes; let i = index">
                                        <input id="ticket_{{i}}" type="checkbox"
                                            [attr.checked]="ticketChecked(ticket._id)"
                                            (change)="checkTicket(ticket._id, $event.target.checked)" />
                                        <label for="ticket_{{i}}">
                                            {{ticket.name}} <span class="ticket_category"> -
                                                {{ticket.ticketTypeCategory?.name || 'Sans catégorie'}}</span>
                                            <svg role="img" title="check" class="check-icon">
                                                <use href="assets/images/sprite.svg#icon-check"></use>
                                            </svg>
                                        </label>
                                    </li>
                                </ul>
                                <div class="dropdown_action">
                                    <app-button (clickEvent)="dropDown = false" text="Sélectionner"
                                        [style]="'plain full'">
                                    </app-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="form_actions right">
                    <app-button [loadingOff]="loadingState$" [style]="'plain'" [disabled]="mainForm.invalid"
                        (clickEvent)="submitForm()" text="Enregistrer" type="submit">
                    </app-button>
                    <app-popup *ngIf="error != null" title="Une erreur est survenue" message="{{error}}"
                        popupOpened="true" (backEvent)="error = null"></app-popup>
                </div>
            </form>
        </div>
    </div>
</section>
