import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { FormComponent, FormFromEventComponent } from 'src/app/elements/form-component';
import { IForm, IFormField } from 'src/app/models/form.model';
import { ITicketType } from 'src/app/models/ticket.model';
import { APICreateResponse, APIResponse, DefaultGetParams, GetParams } from 'src/app/models/type.definition';
import { FormFieldService, FormService, TicketTypeService } from 'src/app/providers';
import { GetEvent } from '../../getEvent';

@Component({
  selector: 'app-event-forms-create',
  templateUrl: './event-forms-create.component.html',
  styleUrls: ['./event-forms-create.component.scss'],
  providers: [GetEvent]
})
export class EventFormsCreateComponent extends FormFromEventComponent<IForm> {
  @ViewChild('app-event-form-fields-component') fieldsSection: FormComponent<IFormField>;
  protected dataName = 'Formulaire';
  public ticketTypes: ITicketType[];
  public checkedTicketTypes: string[] = [];
  public dropDown: boolean = false;
  public fieldsToCreate: IFormField[] = [];
  public fieldsToDelete: string[] = [];
  public fieldsToUpdate: IFormField[] = [];

  constructor(protected formProvider: FormService,
    protected fieldService: FormFieldService,
    protected getEvent: GetEvent,
    protected ticketTypeService: TicketTypeService) {
    super()
  }

  protected async onInit(): Promise<void> {
    this.ticketTypes = await this.getListFromEventOf(this.ticketTypeService,  {
      populate: ['ticketTypeCategoryId'],
      perPage: 500
    });
    const getParams: GetParams<IForm> = DefaultGetParams();
    await this.getDataToEdit('formId', getParams)
    if (this.data) {
      this.checkedTicketTypes = this.data.ticketTypes.ids
    }
  }

  protected initForm(): void {
    this.mainForm = this.formBuilder.group({
      name: new FormControl(this.data?.name, Validators.required),
      eventId: this.event._id,
      location: 'ticket',
      ticketTypes: new FormGroup({
        ids: new FormControl(this.data?.ticketTypesRestricted || [])
      })
    })
  }

  public setTicketType(ticketTypeId: string): void {
    this.setValue(['ticketTypes', 'ids'], [ticketTypeId]);
  }

  public submitForm(): void {

    const formData: IForm = this.mainForm.value;
    formData.ticketTypesRestricted = this.checkedTicketTypes.map(_ => ({ _id: _ }));
    formData.ticketTypes.ids = this.checkedTicketTypes;
    formData.ticketTypes.restrict = !!this.checkedTicketTypes.length
    this.createOrUpdate(formData, async (res: APIResponse | APICreateResponse) => {
      const id = this.data?._id || (<APICreateResponse>res)._id;
      await this.deleteFields();
      await this.createFields(id);
      await this.updateFields();
    }, null, true);
  }

  private async deleteFields(): Promise<void> {
    for (const id of this.fieldsToDelete) {
      try {
        await this.fieldService.delete(id).toPromise();
      }
      catch (err) {
        this.notificationService.newNotification({ message: this.errorHandler.getError(err), state: 'error' });
        this.loadingState$.next();
        this.goBack();
        throw new Error("Deletion error");
      }
    }
  }

  private async createFields(formId: string): Promise<void> {
    for (const field of this.fieldsToCreate) {
      field.formId = formId;
      await this.fieldService.create(field).toPromise();
    }
  }

  private async updateFields(): Promise<void> {
    for (const field of this.fieldsToUpdate) {
      await this.fieldService.update(field._id, field).toPromise();
    }
  }


  @HostListener('document:click') clickDoc(): void {
    this.dropDown = false;
  }

  public checkTicket(id: string, value: boolean): void {
    const inArray = this.checkedTicketTypes.includes(id);
    if (value && !inArray) {
      this.checkedTicketTypes.push(id);
    }
    else if (!value && inArray) {
      this.checkedTicketTypes = this.checkedTicketTypes.filter(_ => _ !== id);
    }
  }

  public ticketChecked(id: string): boolean {
    return this.checkedTicketTypes.includes(id) || null;
  }

  public ticketRestricted(): boolean {
    return this.mainForm.value.ticketTypes.restrict;
  }

  public getTicketsName(): string {
    const names = this.ticketTypes.filter(_ => this.checkedTicketTypes.includes(_._id));
    if (!names.length) {
      return 'Tous';
    }
    else {
      const fullString = names.map(_ => _.name).join(', ');
      return fullString.length < 80 ? fullString : `${fullString.substring(0, 77)} ...`
    }
  }


}
