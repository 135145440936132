import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import * as config from "src/config.json";
import { CountryService, OrganisationService } from 'src/app/providers';
import { Subject } from 'rxjs';
import { FormComponent } from 'src/app/elements/form-component';
import { IOrganisation } from 'src/app/models/organisation.model';

@Component({
  selector: 'app-general-settings',
  templateUrl: './general-settings.component.html',
  styleUrls: ['./general-settings.component.scss']
})
export class GeneralSettingsComponent extends FormComponent<IOrganisation> {
  protected dataName = "Organisation"
  public error: string;
  public errorLoadingImage: boolean = false
  public loadingState$: Subject<void> = new Subject<void>();
  public orgaTypes = config.organisationType;
  public countries: any;
  public dataLoading = { value: false }

  constructor(
    private countryService: CountryService,
    protected formProvider: OrganisationService) {
    super();
  }


  async onInit(): Promise<void> {
    this.errorLoadingImage = false;
    await new Promise<void>(res => {
      this.subscribeTo(this.formProvider.selected$, async data => {
        if (data) {
          await this.handleData(data);
          res();
        }
      });
    });
    this.createImagesChangeState(['imageId']);
    this.countries = await this.getListOf(this.countryService, 'data');
  }

  async handleData(data: IOrganisation) {
    if (data) {
      if (!this.dataLoading.value) {
        this.dataLoading.value = true;
        const keys = ['name', 'type', 'description', 'place', 'contactInfo', 'billingInfo', 'imageId']
        const nextStep = await this.growthService.checkCurrent(data, keys, this.dataLoading, this.formProvider, 'selected$');
        if (nextStep) {
          this.data = data;
          this.getImage();
          this.loaded = true;
        }
      }
    }
  }

  private async getImage(): Promise<void> {
    const id = this.data?.imageId?._id
    if (id) {
      this.loadingImage.push("imageId");
      try {
        this.data.image = await this.mediaService.getStream(id).toPromise();
      } catch (error) {
        this.errorLoadingImage = true;
      } finally {
        this.loadingImage.splice(this.loadingImage.indexOf("imageId"));
      }
    }
  }

  initForm(): void {
    this.mainForm = this.formBuilder.group({
      imageId: new FormControl(null),
      name: new FormControl(this.data.name, Validators.required),
      type: new FormControl(this.data.type, Validators.required),
      description: new FormControl(this.data.description),
      place: this.formBuilder.array([]),
      contactInfo: this.formBuilder.group({
        email: new FormControl(this.data.contactInfo.email),
        phone: new FormControl(this.data.contactInfo.phone),
        webSite: new FormControl(this.data.contactInfo.webSite),
        facebook: new FormControl(this.data.contactInfo.facebook),
        instagram: new FormControl(this.data.contactInfo.instagram),
        twitter: new FormControl(this.data.contactInfo.twitter)
      }),
      billingInfo: this.formBuilder.group({
        siret: new FormControl(this.data.billingInfo.siret),
        licenceNumber: new FormControl(this.data.billingInfo.licenceNumber),
        vatNumber: new FormControl(this.data.billingInfo.vatNumber)
      })
    })
    this.addPlaces();
  }

  createPlace(place = null) {
    return this.formBuilder.group({
      name: new FormControl(place?.name || ''),
      address: new FormControl(place?.address || ''),
      address2: new FormControl(place?.address2 || ''),
      zipcode: new FormControl(place?.zipcode || ''),
      city: new FormControl(place?.city || ''),
      countryId: new FormControl(place?.countryId?._id || '')
    })
  }

  addPlaces() {
    for (const place of this.data.place) {
      this.addPlace(place);
    }
  }

  addPlace(place = null) {
    const places = this.mainForm.get('place') as FormArray;
    places.push(this.createPlace(place));
  }

  removePlace(i: number) {
    const places = this.mainForm.get('place') as FormArray;
    places.removeAt(i);
  }

  setDescriptionValue(value) {
    this.mainForm.patchValue({ description: value });
  }

  submitForm() {
    const imageId = this.data.imageId?._id || undefined;
    this.getFileToCreate(this.mainForm.value.imageId, 'imageId', imageId);
    const form = this.mainForm.value;
    if (!this.imageChanged.imageId.changed) {
      console.log("Delete image from payload");
      delete form.imageId
    }

    this.createMediaAndUpdate(form, 'organisationId', [], res => {
      this.updateObject(form)
      this.filesToCreate = [];
      this.imageChanged.imageId.changed = false;
    });
  }

  updateObject(formData) {
    for (const key in formData) {
      this.data[key] = formData[key];
    }
    this.formProvider.selected$.next(this.data);
  }

  public changeImage(key: string, keyId: string, file: File): void {
    const image = this.data.imageId;
    if ((file && this.errorLoadingImage) || this.imageChanged[keyId].load || !image) {
      this.imageChanged[keyId].changed = true;
    }
    else {
      this.imageChanged[keyId].load = true;
    }
    this.errorLoadingImage = false
    this.setValue(key, file);
  }

  public async cancel(): Promise<void> {
    this.loaded = false;
    setTimeout(() => {
      this.getImage();
      this.loaded = true;
      this.initForm();
      this.loadingState$.next();
      this.notificationService.newNotification({state: "success", message: 'Le formulaire a bien été réinitialisé'})
    }, 200)
  }

}
