import { OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable, Subject, Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { AbstractComponent } from 'src/app/etc/abstract-component'
import { injector } from 'src/app/etc/injector';
import { EditableData, EditableEventData } from 'src/app/models/editable-data';
import { IEvent } from 'src/app/models/event.model';
import { APIError, DefaultGetParams, GetParams, TableOptions } from 'src/app/models/type.definition';
import { GetEvent } from 'src/app/pages/events/getEvent';
import { Provider } from 'src/app/providers/provider';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { NotificationService } from 'src/app/services/notification.service';


/**
 * @abstract Component that provide properties and methods to display a table of data
 */
@AbstractComponent()
export abstract class ListComponent<T extends EditableData> implements OnInit, OnDestroy {
  private _subscription = new Subscription();
  protected currentUrl: string;
  public filtersForm: FormGroup;
  public addFilterOnNextRequest: boolean;
  public hideTable: boolean;
  public totalPages: number;
  public elementToDelete: T;
  public page = 1;
  public loadingState$ = new Subject<void>();
  public totalData: number;

  protected getParams: GetParams<T> = DefaultGetParams();
  protected readonly DefaultToggle: TableOptions = {
    class: 'more',
    toggle: [{
      title: 'Modifier',
      method: this.editData
    },
    {
      title: 'Supprimer',
      class: "red",
      method: this.deleteData
    }]
  };

  /** List of dependencies to inject */
  protected router: Router;
  protected notificationService: NotificationService;
  protected formBuilder: FormBuilder;
  protected errorHandler: ErrorHandlerService;

  protected abstract dataName: string;
  protected abstract provider: Provider<T>;
  public abstract tableOptions: TableOptions[];
  public data: T[];
  public currentTotalData: number;
  public loading: boolean = false;

  constructor() {
    this.formBuilder = injector.get(FormBuilder);
    this.router = injector.get(Router);
    this.notificationService = injector.get(NotificationService);
    this.errorHandler = injector.get(ErrorHandlerService);
  }

  public ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  public async ngOnInit(): Promise<void> {
    this.currentUrl = this.router.url.split('?')[0];
    //this.useFilterStorage();
    await this.beforeInit();
    await this.getData();
    await this.afterInit();
  }

  protected beforeInit(): Promise<void> | void { }

  protected afterInit(): Promise<void> | void { }

  /**
   * Method to override to set `getParams` before `getData` is called
   */
  protected setGetParams(): void {
    this.addFilterForm();
  };

  protected changePage(page: number) {
    this.addFilterOnNextRequest = true;
    this.page = page;
    this.getData();
  }

  protected async getData(): Promise<void> {
    this.loading = true;
    this.setGetParams();
    this.getParams.page = this.page;

    let results = await this.provider.getList(this.getParams).toPromise();
    console.log('getData', results);

    if (results) {
      this.data = await Promise.all(results?.data.map(async element => this.formatElement(element)));
      this.currentTotalData = results.total;
      this.page = results?.pager?.page;
      this.totalPages = results?.pager?.pages;
      this.loadingState$.next();
      this.loading = false;
    }
    else {
      this.data = [];
      this.loadingState$.next();
      this.loading = false;
      this.currentTotalData = 0;
      this.totalPages = 0;
    }
    if (this.totalData == undefined) {
      this.totalData = this.currentTotalData || 0
    }
  }

  public searchByKeyword(input: string, key = "keyword") {
    this.page = 1;
    const searchFilterValue = input?.toLowerCase().trim()
    this.filtersForm.patchValue({ [key]: searchFilterValue });
    this.addFilterOnNextRequest = true;
    this.getData();
  }

  /**
   * Add each setted value of the filter form inside `getParams`
   */
  private addFilterForm(params = this.getParams): void {
    // Remove empty values on default filter form.
      Object.keys(params.filter).forEach((key, index) => {
      if ([undefined, null, ""].includes(params.filter[key])) {
        delete params.filter[key]
      }
    })

    if (this.filtersForm && this.addFilterOnNextRequest) {
      this.addFilterOnNextRequest = false;
      delete params.search
      for (const key in this.filtersForm.value) {
        if (key === "search" && this.filtersForm.value[key]) {
          if (![undefined, null, ""].includes(this.filtersForm.value['search'])) {
            params.search = this.filtersForm.value[key];
          }
        }
        else {
          if (![undefined, null, ""].includes(this.filtersForm.value[key])) {
            params.filter[key] = this.filtersForm.value[key]
          }
          else {
            delete params.filter[key];
          }
        }
      }
    }
  }

  /**
   * Add each setted value of the filter form inside another `GetParams<T>` object
   */
  protected addFilterOnOtherParams(params: GetParams<T>): void {
    this.addFilterOnNextRequest = true;
    this.addFilterForm(params);
  }

  /**
   * Reset the pager and retrieve the data
   */
  public filter(): void {
    this.addFilterOnNextRequest = true;
    this.page = 1;
    this.getData();
  }

  /**
   * Method to format the data after retrieving them from the API.
   * Can be asynchronous
   *
   * @param element The element `T` to format
   * @returns
   */
  protected formatElement(element: T): Promise<T> | T {
    return element
  }

  /**
   * Navigate to the creation page of current type
   * @param ref Used to keep the reference of the component inside the function.
   * This method is used as a callback, therefore there are only three ways to keep the reference of `this` :
   *  - By creating this method as an arrow function and declare it above the parameters using it
   *  - By keeping the reference as a global variable
   *  - By passing the reference as a default parameter and to the component who need to call the method
   *
   * To avoid some messiness, the third option has been chosen
   *
   */
  public createData(ref: ListComponent<T> = this) {
    ref.router.navigate([`${ref.currentUrl}/creer`])
  }

  /**
   * Navigate to the edition page of the wanted element of current type
   * @param data The data to edit
   * @param ref Used to keep the reference of the component inside the function.
   * This method is used as a callback, therefore there are only three ways to keep the reference of `this` :
   *  - By creating this method as an arrow function and declare it above the parameters using it
   *  - By keeping the reference as a global variable
   *  - By passing the reference as a default parameter and to the component who need to call the method
   *
   * To avoid some messiness, the third option has been chosen
   *
   */
  public editData(data: T, ref: ListComponent<T> = this) {
    ref.router.navigate([`${ref.currentUrl}/editer/${data._id}`]);
  }

  /**
   * Delete wanted element of current type
   * @param data The data to delete
   * @param ref Used to keep the reference of the component inside the function.
   * This method is used as a callback, therefore there are only three ways to keep the reference of `this` :
   *  - By creating this method as an arrow function and declare it above the parameters using it
   *  - By keeping the reference as a global variable
   *  - By passing the reference as a default parameter and to the component who need to call the method
   *
   * To avoid some messiness, the third option has been chosen
   *
   */
  public deleteData(data: T, ref: ListComponent<T> = this, callback: () => void = null): void {

    const index = ref.data.findIndex(_ => _._id == data._id);
    ref.provider.delete(data._id).pipe(first()).subscribe(() => {
      ref.data.splice(index, 1);
      ref.notificationService.newNotification({ message: `${ref.dataName} supprimé(e)`, state: 'success' });
      callback && callback()
    }, err => {
      ref.notificationService.newNotification({ message: `Une erreur est survenue, veuillez réessayer ultérieurement`, state: 'error' })
      callback && callback()
    });
  }

  /**
   * Create the sort attribut for the request
   *
   * @param key
   * @param order
   */
  public sort(key: string, order: number): void {
    this.getParams.sort = [[key, order]];
    this.updateFilterStorage();
    this.getData();
  }

  protected updateFilterStorage(): void {
    const key = `${this.dataName.toLowerCase()}_filter`;
    localStorage.setItem(key, JSON.stringify({
      sort: this.getParams.sort, filters: this.filtersForm?.value || { name: this.getParams.filter?.name }
    }))
  }

  private useFilterStorage(): void {
    const key = `${this.dataName.toLowerCase()}_filter`;
    if (!localStorage.getItem(key)) {
      localStorage.setItem(key, JSON.stringify({ sort: null, filters: null }))
    }
    const filters = JSON.parse(localStorage.getItem(key));
    if (filters.sort) {
      this.getParams.sort = filters.sort
    }
    if (this.filtersForm) {
      this._subscription.add(this.filtersForm.valueChanges.subscribe((e) => {
        this.updateFilterStorage()
      }));
      if (filters.filters) {
        this.filtersForm.patchValue({ ...filters.filters });
        this.addFilterOnNextRequest = true;
      }
    }
    else if (filters.filters) {
      this.getParams.filter = { ...(this.getParams.filter || {}), ...filters.filters }
    }
  }

  public getPreDataSearchInput(key = "name"): string {
    const storageKey = `${this.dataName.toLowerCase()}_filter`;
    const filters = JSON.parse(localStorage.getItem(storageKey));
    if (filters.filters && filters.filters[key]) {
      return filters.filters[key];
    }
    return undefined;
  }

  async getListOfData<D extends EditableData>(provider: Provider<D>, params: GetParams<D> = DefaultGetParams()): Promise<D[]> {
    const results = await provider.getList(params).toPromise();
    return results?.data || [];
  }

  /**
   * Subscribe to an observable and executes the callbacks
   *
   * @param observable The observable to subscribe to
   * @param callback Called each time the observable emit a value
   * @param onError Called if an error is catch
   * @param ephemeral [default = false] If true, the observable will be marked with the pipe `first`, else it will be added to the `Subscription`
   */
  public subscribeTo<T>(
    observable: Observable<T>,
    callback: (value: T) => void | Promise<void>,
    onError = (error: APIError) => console.error(error),
    ephemeral: boolean = false): void {
    if (ephemeral) {
      observable.pipe(first()).subscribe(callback, onError);
    }
    else {
      this._subscription.add(observable.subscribe(callback, onError));
    }
  }

}

/**
 * @abstract Component that provide properties and methods to display a table of data from an Event
 */
@AbstractComponent()
export abstract class ListFromEventComponent<T extends EditableEventData> extends ListComponent<T> implements OnInit {
  protected abstract getEvent: GetEvent;
  protected getEventParams: GetParams<IEvent> = {}
  public event: IEvent;

  constructor() {
    super();
  }

  async ngOnInit(): Promise<void> {
    this.event = await this.getEvent.get(this.getEventParams);
    this.getParams.filter.eventId = this.event._id;
    super.ngOnInit();
  }

  /**
   * Call `getListOfData` with the eventId filter set to the id of current event
   *
   * @param provider
   * @param getParams
   * @returns
   */
  async getListOfDataOfEvent<D extends EditableEventData>(
    provider: Provider<D>,
    getParams: GetParams<D> = DefaultGetParams()
  ): Promise<D[]> {
    if (!getParams.filter) getParams.filter = {};
    getParams.filter.eventId = this.event._id;
    return await this.getListOfData(provider, getParams);
  }

  /**
   * Navigate to the wanted url relative to the root page of the current event
   *
   * @param path
   */
  public goTo(path: string): void {
    const splittedUrl = this.currentUrl.split("/");
    const root = `${splittedUrl[1]}/${splittedUrl[2]}`
    this.router.navigateByUrl(`${root}/${path}`)
  }

}
