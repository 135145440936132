import { Component, OnInit } from '@angular/core';
import { AuthService, EventService, OrganisationService, UserService } from 'src/app/providers';
import { Subject, Subscription } from 'rxjs';
import User from 'src/app/models/user.model';
import { Router } from '@angular/router';
import { ClickOutService } from 'src/app/services/click-out.service';

// Animations
import { menuAnimation } from 'src/app/animations/animations';
import { DateService } from 'src/app/services/date.service';
import { TableOptions } from 'src/app/models/type.definition';
import { IEvent } from 'src/app/models/event.model';
import { NotificationService } from 'src/app/services/notification.service';
import { StorageService } from 'src/app/services/storage.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss'],
  animations: [
    menuAnimation
  ]
})
export class EventsComponent implements OnInit {
  private _subscription = new Subscription();
  public filtersDisplay: boolean;
  private statusTranslate = {
    draft: "Brouillon",
    published: "En cours",
    passed: "Terminé",
    closed: "Terminé"
  }
  public filterLoading: boolean;
  public init: boolean;
  public getOptions: any;
  public page = 1;
  public totalPages: number;
  public totalEvents: number;
  public eventToDuplicate: IEvent;
  public me: User;
  public filters: { name: string, key: string, value: any }[] = [];
  public eventToDelete: IEvent = null;
  public statusArray = [{
    name: "draft",
    trad: 'Brouillon',
    checked: false
  },
  {
    name: "published",
    trad: 'En cours',
    checked: true
  },
  {
    name: "passed",
    trad: 'Terminé',
    checked: false
  }]
  public dateFilter: { start?: string, end?: string } = {};
  public events: IEvent[];
  public tableOptions: TableOptions[] = [
    {
      title: "Nom",
      class: "name",
      sort: true,
      key: 'name',
      sortMethod: order => this.sort("name", order),
    },
    {
      title: "Date",
      class: "date",
      sort: true,
      key: 'date',
      sortMethod: order => this.sort("dates.startDate", order)
    },
    {
      title: "Participants",
      class: "participants",
      sort: true,
      key: 'participants',
      sortMethod: order => this.sort("ticketing.quota", order)
    },
    {
      title: "Total net",
      class: "total",
      sort: true,
      key: 'price',
      sortMethod: order => this.sort("ticketing.totalPrice.sellingPrice", order),
    },
    {
      title: "Statut",
      class: "status",
      sort: true,
      key: 'translatedStatus',
      classKey: 'status',
      sortMethod: order => this.sort("status", order)
    },
    {
      class: "more",
      toggle: [
        {
          title: 'Administrer',
          method: this.selectEvent
        },
        {
          title: 'Dupliquer',
          method: this.duplicate
        },
        {
          title: 'Aperçu en ligne',
          method: this.preview
        },
        {
          title: 'Supprimer l\'événement',
          class: "red",
          if: (data: IEvent) => this.authService.isAdmin && data.status == 'draft',
          method: (data: IEvent) => this.eventToDelete = data
        }
      ]
    }
  ]

  constructor(
    private eventService: EventService,
    private userService: UserService,
    private organisationService: OrganisationService,
    private clickOutService: ClickOutService,
    private notification: NotificationService,
    private router: Router,
    private dateService: DateService,
    private storage: StorageService,
    private authService: AuthService
  ) { }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }


  ngOnInit(): void {
    this.getOptions = {
      filter: {
        organisationId: this.storage.getItem('organisationId')
      },
      sort: [['dates.startDate', -1]]
    }

    this.setFilter({ startDate: null, endDate: null }, true);
    setTimeout(() => {
      this.setFilter({ startDate: null, endDate: null, status: this.statusArray.filter(_ => _.checked)});
    }, 500);

    this._subscription.add(this.userService.me$.subscribe(me => {
      this.me = me;
    }));
    this._subscription.add(this.clickOutService.closeToggle$.subscribe(() => {
      this.filtersDisplay = false;
    }));
  }

  createEvent(): void {
    this.router.navigate(['evenements/creer-un-evenement']);
  }

  calcParticipants(event: IEvent): string {
    const quota = event.ticketing.quota || 0;
    return event.stats.participants + '/' + (quota || 'ꝏ');
  }

  selectEvent(event: IEvent, ref = this) {
    ref.eventService.selectEvent(event);
    ref.router.navigate(['evenements/' + event._id + '/general'])
  }

  duplicate(event: IEvent, ref = this): void {
    ref.eventToDuplicate = event;
  }

  duplicateOk(name: string): void {
    this.eventService.duplicate(this.eventToDuplicate._id, name).subscribe(res => {
      this.eventService.getById(res.id).subscribe(event => {
        this.selectEvent(event);
      })
    }, err => {
      this.notification.newNotification({
        message: "Une erreur est survenue, veuillez réessayer plus tard",
        state: "error"
      });
      this.eventToDuplicate = null;
    })
  }

  preview(event: IEvent, ref = this) {
    ref.eventService.selectEvent(event);
    ref.router.navigate(['evenements/' + event._id + '/mini-sites'])
  }

  sort(key: string, order: number) {
    this.getOptions.sort = [[key, order]]
    this.getData();
  }

  changePage(page: number) {
    this.page = page;
    this.getData();
  }

  getData(init: boolean = false): void {
    this.filterLoading = true;
    this.getOptions.page = this.page;

    this._subscription.add(this.eventService.getList(this.getOptions).subscribe(async (result: any) => {
      if (init) {
        this.totalEvents = result?.total;
        this.init = true;
      }
      this.totalPages = result?.pager?.pages;
      this.events = result?.data?.map(event => {
        event.translatedStatus = this.statusTranslate[event.status];
        event.price = (event.stats.costPrice / 100) + "€";/** @todo Changer selon devise ? */
        event.participants = this.calcParticipants(event);
        event.date = event.dates.startDate ? this.dateService.formatDate(event.dates.startDate) : 'Session';
        return event
      }) || [];
      this.filterLoading = false;
    }));
  }

  displayFilters(value: boolean) {
    this.filtersDisplay = value;
  }

  searchEvent(input: string) {
    this.page = 1;
    const searchFilterValue = input?.toLowerCase().trim();
    this.getOptions.filter = this.getOptions.filter || { organisationId: this.storage.getItem('organisationId') };
    this.getOptions.search = searchFilterValue
    this.getData();
  }


  setFilter(event, init: boolean = false) {
    this.dateFilter = {
      start: event.startDate,
      end: event.endDate
    }
    this.filters = [];
    this.getOptions.filter = { organisationId: this.storage.getItem('organisationId') };
    if (event.startDate || event.endDate) {
      const startDate = event.startDate && this.dateService.formatDate(event.startDate);
      const endDate = event.endDate && this.dateService.formatDate(event.endDate);
      /** @Todo Filter by dates in API */
      const date = {
        startDate: event.startDate,
        endDate: event.endDate
      }
      const name = event.startDate ? event.endDate ? `${startDate} au ${endDate}` : `à partir du ${startDate}` : `jusqu'au ${endDate}`
      this.filters.push({ name, key: 'date', value: date });
      this.getOptions.filter.date = JSON.stringify(date);
    }
    this.filters = this.filters.filter(_ => _.key == 'date');
    if (event.status?.length) {
      this.getOptions.filter.status = []
      for (const status of event.status) {
        if (!this.filters.includes(status.trad)) {
          this.filters.push({ name: status.trad, key: 'status', value: status.name });
          this.getOptions.filter.status.push(status.name);
        }
      }
    }
    this.getData(init);
    setTimeout(() => this.displayFilters(false));
  }

  deleteFilter(i: number) {
    if (this.filters[i].key == 'status') {
      this.statusArray.find(_ => _.name == this.filters[i].value).checked = false;
      const index = this.getOptions.filter.status.findIndex(_ => JSON.stringify(_) == JSON.stringify(this.filters[i].value));
      this.getOptions.filter.status.splice(index, 1);
    }
    else {
      this.dateFilter = {};
      delete this.getOptions.filter.date;
    }
    this.filters.splice(i, 1);
    this.getData();
  }

  resetFilter() {
    this.filters = [];
    this.getOptions.filter = { organisationId: this.storage.getItem('organisationId') };
    this.statusArray.forEach(_ => _.checked = false);
    this.dateFilter = {};
    this.getData();
  }

  deleteOk() {
    const index = this.events.findIndex(_ => _._id == this.eventToDelete._id);
    this.eventService.delete(this.eventToDelete._id).pipe(first()).subscribe(() => {
      this.events.splice(index, 1);
      this.notification.newNotification({ message: `Événement supprimé(e)`, state: 'success' });
      this.eventToDelete = null
    }, err => {
      this.notification.newNotification({ message: `Une erreur est survenue, veuillez réessayer ultérieurement`, state: 'error' })
      this.eventToDelete = null
    });
  }

}
