import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IMember } from 'src/app/models/member.model';
import { APIResponse } from 'src/app/models/type.definition';
import { Provider } from '../provider';
import { IUserOrganisation } from 'src/app/models/user-organisation.model';

@Injectable()
export class MemberService extends Provider<IMember>{
    protected url = "users/organisations/members";
}
