<section
  id="form_page"
  class="container loading_container"
  [ngClass]="{ loaded: ticketingSettingForm }"
>
  <div class="loading_content">
    <div id="page_header">
      <h1>Paramètres</h1>
      <app-button
        (clickEvent)="goBack()"
        text="retour"
        [style]="'border blue'"
      ></app-button>
    </div>
    <div id="form_wrapper">
      <h2>Paramétrer la billetterie</h2>
      <form *ngIf="ticketingSettingForm" [formGroup]="ticketingSettingForm">
        <h3>Informations globales</h3>

        <div *ngIf="isAdmin" formGroupName="commission" class="form_cols admin_only">
          <div class="form_item half">
            <label for="name">Commission variable</label>
            <input id="name" type="number" formControlName="percentage" />
          </div>
          <div
            *ngIf="event && ![undefined, null].includes(event.ticketing?.stock)"
            class="form_item half"
          >
            <label for="name">Commission fixe</label>
            <input id="name" type="number" formControlName="flat" />
          </div>
        </div>

        <div formGroupName="ticketing" class="form_cols">
          <div *ngIf="isAdmin" class="form_item full smallest_margin admin_only">
            <input id="hasBadge" type="checkbox" formControlName="hasBadge" />
            <label for="hasBadge">
              Générer des badges en plus des e-tickets
              <svg role="img" title="check" class="check-icon">
                <use href="assets/images/sprite.svg#icon-check"></use>
              </svg>
            </label>
          </div>

          <div class="form_item half">
            <label for="name">Quota global maximum</label>
            <input id="name" type="text" formControlName="quota" />
          </div>
          <div
            *ngIf="event && ![undefined, null].includes(event.ticketing?.stock)"
            class="form_item half"
          >
            <label for="name">Stock Actuel</label>
            <input
              id="name"
              type="text"
              disabled
              [value]="event.ticketing?.stock"
            />
          </div>

          <app-date-interval
            class="form_item full"
            (changeTimeZone)="selectTimeZone($event)"
            [timeZone]="event.ticketing?.date && event.ticketing?.date.timezone"
            (newDates)="changeDates($event)"
            startText="Ouverture de la billetterie"
            endText="Fermeture de la billetterie"
            [preData]="event.ticketing?.date"
          >
          </app-date-interval>
          <!-- <div class="form_item">
                        <input id="check-in-quota" type="checkbox">
                        <label for="check-in-quota">
                            Prendre en compte la vente de tickets au guichet dans le quota global
                            <svg role="img" title="check" class="check-icon">
                                <use href="assets/images/sprite.svg#icon-check"></use>
                            </svg>
                        </label>
                    </div> -->
          <div class="form_item full smallest_margin">
            <input id="in_out" type="checkbox" formControlName="inOut" />
            <label for="in_out">
              Scan entrée et sortie
              <svg role="img" title="check" class="check-icon">
                <use href="assets/images/sprite.svg#icon-check"></use>
              </svg>
            </label>
          </div>
          <h3 class="form_item full">Gestion des réservations</h3>
          <div class="form_item two_thirds">
            <label for="booking-time">Durée limite de réservation</label>
            <div class="input_wrap select">
              <select id="booking-time" formControlName="bookingTime">
                <option
                  *ngFor="let time of bookingTimeOptions"
                  [ngValue]="time[2]"
                >
                  {{ time[0] }}
                  {{ time[1] }}
                </option>
              </select>
              <svg role="img" title="chevron" class="icon-chevron">
                <use href="assets/images/sprite.svg#icon-chevron"></use>
              </svg>
            </div>
          </div>

          <h3 class="form_item full">Gestion des Addons</h3>
          <div class="form_item full">
            <div class="form_item full">
              <input
                id="minimum-amount-addon"
                type="checkbox"
                [checked]="hasAddonMinimum"
                (change)="changeAddOnMinimum($event.target.checked)"
              />
              <label for="minimum-amount-addon">
                Définir un minimum d'addon par ticket
                <svg role="img" title="check" class="check-icon">
                  <use href="assets/images/sprite.svg#icon-check"></use>
                </svg>
              </label>
            </div>
            <div
            *ngIf="hasAddonMinimum"
            class="form_item half">
              <label for="name">Addon(s) minimum par ticket</label>
              <input
                id="minimumAddon"
                type="number"
                min="0"
                formControlName="minimumAddonAmount"
              />
            </div>
          </div>
          <div class="form_item full">
            <div class="form_item full">
              <input
                id="limit-amount-addon"
                type="checkbox"
                [checked]="hasAddonLimit"
                (change)="changeAddOnLimit($event.target.checked)"
              />
              <label for="limit-amount-addon">
                Définir un maximum d'addon par ticket
                <svg role="img" title="check" class="check-icon">
                  <use href="assets/images/sprite.svg#icon-check"></use>
                </svg>
              </label>
            </div>
            <div
            *ngIf="hasAddonLimit"
            class="form_item half">
              <label for="name">Addon(s) maximum par ticket</label>
              <input
                id="amountAddon"
                type="number"
                min="0"
                formControlName="limitAddonAmount"
              />
            </div>
          </div>

          <h3 class="form_item full">Termes et conditions personnalisés</h3>
          <div class="form_item full" formGroupName="termAndConditions">
            <div class="form_item">
              <label for="term_text">Texte</label>
              <textarea id="term_text" formControlName="text"></textarea>
            </div>
            <div class="form_item">
              <label for="term_url">URL</label>
              <input id="term_url" type="text" formControlName="url" />
            </div>
          </div>
        </div>

        <h3>Gestion des e-mails</h3>
        <div formGroupName="emailAlerts" class="form_cols">
          <div class="form_item full smallest_margin">
            <input
              id="daily-summary"
              type="checkbox"
              formControlName="dailySummary"
            />
            <label for="daily-summary">
              M'envoyer un récapitulatif des ventes quotidiennes
              <svg role="img" title="check" class="check-icon">
                <use href="assets/images/sprite.svg#icon-check"></use>
              </svg>
            </label>
          </div>
          <div class="form_item full smallest_margin">
            <input
              id="weekly-summary"
              type="checkbox"
              formControlName="weeklySummary"
            />
            <label for="weekly-summary">
              M'envoyer un récapitulatif des ventes de la semaine
              <svg role="img" title="check" class="check-icon">
                <use href="assets/images/sprite.svg#icon-check"></use>
              </svg>
            </label>
          </div>
          <div class="form_item full smallest_margin">
            <input
              id="each-order"
              type="checkbox"
              formControlName="eachOrder"
            />
            <label for="each-order">
              Recevoir un e-mail pour chaque commande passée
              <svg role="img" title="check" class="check-icon">
                <use href="assets/images/sprite.svg#icon-check"></use>
              </svg>
            </label>
          </div>
          <div class="form_item full smallest_margin">
            <input
              id="custom-each-order"
              type="checkbox"
              formControlName="customMailingList"
            />
            <label for="custom-each-order">
              Recevoir sur une liste personnalisés un e-mail pour chaque
              commande passée
              <svg role="img" title="check" class="check-icon">
                <use href="assets/images/sprite.svg#icon-check"></use>
              </svg>
            </label>
          </div>
        </div>
        <div class="form_item full">
          <mat-form-field
            *ngIf="
              ticketingSettingForm.controls['emailAlerts'].controls[
                'customMailingList'
              ].value
            "
            formArrayName="mailingList"
          >
            <mat-chip-list
              class="form_item full "
              #emailList
              aria-label="Custom mailing list"
            >
              <mat-chip
                *ngFor="let ct of emailsOrderForm.controls; index as i"
                [selectable]="selectable"
                [removable]="removable"
                (removed)="removeEmail(i)"
                selected
              >
                {{ ct.get("value").value }}
                <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
              </mat-chip>

            </mat-chip-list>
            <input
                type="text"
                placeholder="Nouvelle adresse email ..."
                [matChipInputFor]="emailList"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                [matChipInputAddOnBlur]="addOnBlur"
                (matChipInputTokenEnd)="add($event)"
              />
          </mat-form-field>
        </div>
        <div class="form_actions right">
          <app-button
            [loadingOff]="loadingState$"
            [style]="'plain'"
            type="submit"
            [disabled]="ticketingSettingForm.invalid"
            (clickEvent)="updateEvent()"
            text="Enregistrer"
          >
          </app-button>
        </div>
      </form>
    </div>
  </div>
</section>
