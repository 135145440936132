<header>
	<div id="header_left">
		<ul id="breadcrumb">
			<li *ngFor="let crumb of breadcrumb">
				{{crumb}}
			</li>
		</ul>
	</div>
	<div id="header_right">
		<ng-container *ngIf="currentOrganisation && !isAdmin">
			<div id="select_organisation">
				<select #selectOrganisation (change)="changeOrganisation(selectOrganisation.value)">
					<option value="">Changer d'organisation</option>
					<option *ngFor="let organisation of organisations"
						[selected]="organisation.id == currentOrganisation.id"
						[hidden]="organisation.id == currentOrganisation.id" value="{{organisation.id}}">
						{{organisation.name}}
					</option>
				</select>

				<svg role="img" title="chevron" class="icon-chevron">
					<use href="assets/images/sprite.svg#icon-chevron"></use>
				</svg>
			</div>
			<app-organisation-notifications-menu></app-organisation-notifications-menu>
		</ng-container>
		<div id="user_menu_wrapper">
			<div id="toggle_user_menu" (click)="displayAccountMenu(true); $event.stopPropagation()">
				<figure>
					<img [src]="avatar" alt="Mon compte" />
				</figure>
				<svg role="img" title="chevron" class="icon-chevron">
					<use href="assets/images/sprite.svg#icon-chevron"></use>
				</svg>
			</div>
			<div id="user_menu" *ngIf="accountMenuIsDisplay" (click)="$event.stopPropagation()" [@menuAnimation]>
				<span class="title">Menu</span>
				<button class="close" (click)="displayAccountMenu(false)">
					<svg role="img" title="close" class="icon-close">
						<use href="assets/images/sprite.svg#icon-close"></use>
					</svg>
				</button>
				<ul>
					<li>
						<a (click)="goToMyAccountPage()">Mon Compte</a>
					</li>
					<li>
						<a (click)="logOut()">Déconnexion</a>
					</li>
				</ul>
			</div>
		</div>
	</div>
</header>