<section id="form_page" class="container loading_container" [ngClass]="{ 'loaded' : promoForm }">
    <div class="loading_content">
        <div id="page_header">
            <h1>Codes promo</h1>
            <app-button (clickEvent)="goBack()" text="retour" [style]="'border blue'"></app-button>
        </div>
        <div id="form_wrapper">
            <h2>Modifier un code promo</h2>

            <form *ngIf="promoForm" [formGroup]="promoForm">
                <div class="form_item">
                    <label for="name">Libellé</label>
                    <input id="name" type="text" formControlName="name">
                </div>
                <div class="form_item">
                    <label for="code">Code</label>
                    <input id="code" type="text" formControlName="code">
                </div>
                <div class="form_item">
                    <label for="uses">Nombre d'utilisation maximum du code promo</label>
                    <input id="uses" type="number" formControlName="constraintQuantity">
                </div>
                <div class="form_item">
                    <label for="counterScope">Calcul du stock par</label>
                    <div class="form_radios" (change)="changeCounterScope($event.target.value)">
                        <input id="scope_order" type="radio" value="order" formControlName="counterScope">
                        <label for="scope_order">Commande passée</label>
                        <input id="scope_ticket" type="radio" value="ticket" formControlName="counterScope">
                        <label for="scope_ticket">Ticket acheté</label>
                    </div>
                </div>
                <div class="form_cols" formGroupName="reduction">
                    <div class="form_item half">
                        <label for="type">Réduction</label>
                        <div class="form_radios" (change)="changeDiscount($event.target.value)">
                            <input id="discount_percent" type="radio" value="percentage" formControlName="type">
                            <label for="discount_percent">En pourcentage</label>
                            <input id="discount_euro" type="radio" value="flat" formControlName="type">
                            <label for="discount_euro">En devise</label>
                            <input id="discount_free" type="radio" value="free" formControlName="type" *ngIf="event.bookType!='nft'">
                            <label for="discount_free" *ngIf="event.bookType!='nft'">Gratuit</label>
                        </div>
                    </div>
                    <div *ngIf="discount !== 'free'" class="form_item half has_suffix mt_50">
                        <input id="amount" type="number" formControlName="amount">
                        <span class="suffix">{{discount}}</span>
                    </div>
                </div>
                <div class="form_item">
                    <input [checked]="restrictTicket" id="is-tickets-restricted" type="checkbox"
                        (change)="displayRestrictedTicket()">
                    <label for="is-tickets-restricted">
                        Ce code promo s'applique seulement sur certains tarifs
                        <svg role="img" title="check" class="check-icon">
                            <use href="assets/images/sprite.svg#icon-check"></use>
                        </svg>
                    </label>
                </div>

                <div *ngIf="restrictTicket" class="form_item ">
                    <div *ngIf="ticketsType.length" class="input_wrap select multiple">
                        <span class="select_multiple_toggle" (click)="dropDown = !dropDown; $event.stopPropagation()">
                            {{getTicketsName()}}
                        </span>
                        <svg role="img" title="chevron" class="icon-chevron">
                            <use href="assets/images/sprite.svg#icon-chevron"></use>
                        </svg>
                        <div class="dropdown multiple" *ngIf="dropDown" (click)="$event.stopPropagation()">
                            <ul>
                                <li *ngFor="let ticket of ticketsType; let i = index">
                                    <input id="ticket_{{i}}" type="checkbox" [attr.checked]="ticketChecked(ticket._id)"
                                        (change)="checkTicket(ticket._id, $event.target.checked)" />
                                    <label for="ticket_{{i}}">
                                        {{ticket.name}} <span class="ticket_category"> - {{ticket.ticketTypeCategory?.name || 'Sans catégorie'}}</span>
                                        <svg role="img" title="check" class="check-icon">
                                            <use href="assets/images/sprite.svg#icon-check"></use>
                                        </svg>
                                    </label>
                                </li>
                            </ul>
                            <div class="dropdown_action">
                                <app-button (clickEvent)="dropDown = false" text="Sélectionner" [style]="'plain full'">
                                </app-button>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="!ticketsType.length" class="form_fields">
                        <div class="form_item">
                            <input type="text" value="Aucun ticket crée" disabled>
                        </div>
                    </div>
                </div>
                <div class="form_item">
                    <input [checked]="restrictDate" id="is-date-restricted" type="checkbox"
                        (change)="displayRestrictedDate()">
                    <label for="is-date-restricted">
                        Ce code promo est temporaire dans le temps
                        <svg role="img" title="check" class="check-icon">
                            <use href="assets/images/sprite.svg#icon-check"></use>
                        </svg>
                    </label>
                </div>
                <div class="form_item" *ngIf="restrictDate">
                    <app-date-interval (newDates)="changeDates($event)" startText="Date de début de validité"
                        endText="Date de fin de validité" [preData]="dates"></app-date-interval>
                </div>

                <p *ngIf="event.bookType=='nft'" class="description_medium"><strong>Note importante :</strong> Vous êtes sur un événement de type NFT. De ce fait il y a des contraintes sur les codes promo, à savoir : <p>
                <p *ngIf="event.bookType=='nft'" class="description_medium">Un code promo ne peut rendre un tarif pour un ticket inférieur à 5,00 €. Tout code promo appliquée qui ferait une réduction pour obtenir un billet gratuit ou un billet inférieur à 5,00 € sera considéré comme invalide.</p>

                <div class="form_actions right">
                    <app-button (clickEvent)="goBack()" text="Annuler" [style]="'border blue'"></app-button>
                    <app-button [loadingOff]="loadingState$" [style]="'plain'" [disabled]="promoForm.invalid || noAmount() || noDate()"
                        (clickEvent)="updatePromoCode()" text="Enregistrer">
                    </app-button>
                    <app-popup *ngIf="error != null" title="Une erreur est survenue" message="{{error}}" popupOpened="true"
                        (backEvent)="error = null"></app-popup>
                </div>
            </form>
        </div>
    </div>
</section>