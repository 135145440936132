import { Component, HostListener } from '@angular/core';
import { GetEvent } from '../getEvent';
import { OrderService, CurrencyService, EventWidgetService, RefundService, TicketService } from 'src/app/providers';
import { moreAnimation } from 'src/app/animations/animations';
import { GetParams, TableOptions } from 'src/app/models/type.definition';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { IEvent } from 'src/app/models/event.model';
import { ListFromEventComponent } from 'src/app/elements/list-component';
import { IOrder, translateStatus, translatePaymentMethod, translatedOrigin } from 'src/app/models/order.model';
import { DateService } from 'src/app/services/date.service';
import { IWidget } from 'src/app/models/widget.model';
import { FilesHandler } from 'src/app/services/file-handler.service';
import { ITicket } from 'src/app/models/ticket.model';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-event-orders',
  templateUrl: './event-orders.component.html',
  styleUrls: ['./event-orders.component.scss'],
  providers: [GetEvent],
  animations: [
    moreAnimation
  ]
})
export class EventOrdersComponent extends ListFromEventComponent<IOrder>  {
  public filtersForm: FormGroup;
  public moreFilters: boolean = false;
  public dataName = "Commande";
  public selectedOrderIds: string[] = [];
  public selectedStatus: string;
  public selectedOrigin: string;
  public actionToggleOpen = false;
  public exportToggleOpen = false;
  public fullLoader: boolean;
  protected getEventParams: GetParams<IEvent> = {
    select: ['ticketing', 'status', 'organisationId'],
  }
  public orderToPay: IOrder
  public resendConfirmOfOrder: IOrder;
  public orderToCancel: IOrder
  public orderToRefund: IOrder
  public widgets: IWidget[];


  public isProduct = this.route.snapshot.data.isProduct

  public tableOptions: TableOptions<IOrder>[] = [
    {
      title: "Origine",
      class: "origin",
      classKey: "origin",
      tooltip: true,
      tooltipKey: 'translatedOrigin',
      sort: true,
      hover: true,
      sortMethod: order => this.sort("origin", order),
    },
    {
      title: "Commande",
      class: "name",
      sort: false,
      key: 'name',
    },
    {
      title: "N° de commande",
      class: "number",
      subClass: "doselect",
      sort: true,
      tooltip: false,
      key: 'orderNumber',
      sortMethod: order => this.sort("orderNumber", order),

    },
    {
      title: "Date",
      class: "date",
      sort: true,
      key: 'date',
      sortMethod: order => this.sort("createdAt", order),
    },
    {
      title: "Montant",
      class: "amount",
      sort: true,
      key: 'amount',
      sortMethod: order => this.sort("totalPrice.sellingPrice", order),
    },
    {
      title: "Statut",
      class: "status",
      classKey: "status",
      sort: true,
      key: 'translatedStatus',
      sortMethod: order => this.sort("status", order),

    },
    {
      class: "more",
      toggle: [
        {
          title: 'Voir le détail',
          method: this.view
        },
        {
          title: 'Renvoyer la confirmation',
          if: data => ['paid', 'partiallyPaid', 'notPaid', 'invitation'].includes(data.status),
          method: this.resendConfirm
        },
        {
          title: 'Télécharger la facture',
          if: data => ['completed'].includes(data.status) && ['web', 'ticketOffice'].includes(data.origin),
          method: this.download
        },
        {
          title: "Télécharger les tickets",
          if: data => ['completed'].includes(data.status) && (this.event.bookType !== 'nft'),
          method: this.downloadTickets
        },
        {
          title: 'Télécharger les badges',
          if: data => this.event.ticketing?.hasBadge,
          method: this.downloadBadges
        },
        {
          title: 'Annuler et rembourser',
          if: data => ['paid', 'partiallyPaid'].includes(data.status) && !!data.totalPrice.sellingPrice,
          method: this.askRefund
        },
        {
          title: 'Annuler sans rembourser',
          if: data => ['paid', 'partiallyPaid', 'notPaid', 'invitation'].includes(data.status) && !!data.totalPrice.sellingPrice,
          class: "red",
          method: this.cancelOrder
        },
        {
          title: 'Annuler la commande',
          if: data => ['paid', 'partiallyPaid'].includes(data.status) && !data.totalPrice.sellingPrice,
          class: "red",
          method: this.cancelOrder
        },
        {
          title: 'Marquée comme "payée"',
          if: data => ['notPaid'].includes(data.status),
          method: this.payOrder
        }
      ]
    }
  ]

  constructor(
    protected provider: OrderService,
    protected ticketService: TicketService,
    protected formBuilder: FormBuilder,
    protected getEvent: GetEvent,
    private dateService: DateService,
    private currencyService: CurrencyService,
    private widgetService: EventWidgetService,
    private filesHandler: FilesHandler,
    private refundService: RefundService,
    protected route: ActivatedRoute
  ) {
    super();
  }

  protected async afterInit(): Promise<void> {
    this.initForm();
    this.widgets = await this.getListOfDataOfEvent(this.widgetService);
  }

  protected setGetParams(): void {
    this.getParams.filter.isProduct = this.isProduct
    this.getParams.filter.status = this.selectedStatus || 'pending,completed,canceled';
    this.getParams.filter.origin = this.selectedOrigin || null;
    this.getParams.populate = ['eventWidgetId', "payments"]
    super.setGetParams();
  }
  protected formatElement(order: IOrder): IOrder {
    order.translatedStatus = translateStatus(order.status);
    order.date = this.dateService.formatDateAndTime(order.createdAt);
    order.amount = order.totalPrice.sellingPrice / 100 + "" + this.currencyService.getCurrencySymbol(order.totalPrice.currency);
    order.totalPrice.sellingPrice /= 100;
    order.translatedMethod = translatePaymentMethod(order.payments[0]?.method);
    order.translatedOrigin = translatedOrigin(order.origin)
    if (order.invoiceData?.firstName && order.invoiceData?.lastName) {
      order.name = `${order.invoiceData.firstName} ${order.invoiceData.lastName}`
    }
    else {
      order.name = "Aucun acheteur"
    }

    if (order.origin == 'invitation') {
      order.amount = 'Gratuit'
    }
    order.type = order.isProduct ? "Produit(s)" : "Ticket(s)";
    return order;
  }

  initForm(): void {
    this.filtersForm = this.formBuilder.group({
      createdAt__$gte: new FormControl(),
      createdAt__$lte: new FormControl(),
      "totalPrice.sellingPrice__$gte": new FormControl(),
      "totalPrice.sellingPrice__$lte": new FormControl(),
      isProduct: new FormControl(this.isProduct),
      eventWidgetId: new FormControl(""),
      "insurance.type": new FormControl(""),
      search: new FormControl()
    });
  }

  public setSearch(search: string): void {
    this.filtersForm.patchValue({ search })
  }

  public view(order: IOrder, ref = this): void {
    ref.router.navigate(['/evenements/' + ref.event._id + '/commandes/' + order._id]);
  }

  public resendConfirm(order: IOrder, ref = this): void {
    ref.resendConfirmOfOrder = order;
  }

  public resendConfirmOk(): void {
    this.provider.resendAnOrderConfirmation(this.resendConfirmOfOrder._id).subscribe(_result => {
      this.resendConfirmOfOrder = null
      if (_result) {
        this.notificationService.newNotification({
          message: 'La confirmation a bien été renvoyée',
          state: 'success'
        })
      }
      else {
        this.notificationService.newNotification({
          message: 'Une erreur s\'est produite, veuillez réessayer plus tard',
          state: 'error'
        })
      }
    });
  }

  public downloadBadges(order: IOrder, ref = this): void {
    ref.fullLoader = true;
    ref.actionToggleOpen = false;
    ref.ticketService.streamOrder(order._id, order.token, 'orderIdTickets', 'badge').subscribe(result => {
      ref.fullLoader = false;
      ref.filesHandler.downloadFile(result, 'Commande n°' + order.orderNumber)
    }, err => {
      ref.notificationService.newNotification({ state: 'error', message: ref.errorHandler.getError(err) });
      ref.fullLoader = false;
    });
  }

  public downloadTickets(order: IOrder, ref = this): void {
    ref.fullLoader = true;
    ref.actionToggleOpen = false;
    ref.ticketService.streamOrder(order._id, order.token, 'orderIdTickets').subscribe(result => {
      ref.fullLoader = false;
      ref.filesHandler.downloadFile(result, 'Commande n°' + order.orderNumber)
    }, err => {
      ref.notificationService.newNotification({ state: 'error', message: ref.errorHandler.getError(err) });
      ref.fullLoader = false;
    });
  }

  public download(order: IOrder, ref = this): void {
    ref.fullLoader = true;
    ref.actionToggleOpen = false;
    ref.provider.streamOrder(order._id, order.token).subscribe(result => {
      ref.fullLoader = false;
      ref.filesHandler.downloadFile(result, 'Facture commande n°' + order.orderNumber)
    }, err => {
      ref.notificationService.newNotification({ state: 'error', message: ref.errorHandler.getError(err) });
      ref.fullLoader = false;
    });
  }

  public askRefund(order: IOrder, ref = this): void {
    ref.orderToRefund = order;
  }
  public async askRefundOk(): Promise<void> {
    await this.abandonOrder(this.orderToRefund);
    await this.refundService.create({
      orderId: this.orderToRefund,
      eventId: this.event,
      status: 'todo'
    }).toPromise();
    this.orderToRefund = null;
  }

  public payOrder(order: IOrder, ref = this): void {
    ref.orderToPay = order;
  }

  public async payOrderOk(): Promise<void> {
    const result = await this.provider.update(this.orderToPay._id, { status: 'completed' }).toPromise()
    if (result) {
      this.getData();
      this.notificationService.newNotification({
        message: 'La commande a bien été marquée comme "payée"',
        state: 'success'
      })
    }
    else {
      this.notificationService.newNotification({
        message: "Une erreur est survenue, la commande n'a pas pu être marquée comme payée",
        state: 'error'
      })
    }
    this.orderToPay = null
  }

  public cancelOrder(order: IOrder, ref = this): void {
    ref.orderToCancel = order;
  }

  public async cancelOrderOk(): Promise<void> {
    await this.abandonOrder(this.orderToCancel);
    this.orderToCancel = null;
  }



  public async abandonOrder(order: IOrder): Promise<void> {
    const result = await this.provider.update(order._id, { status: 'canceled' }).toPromise()
    if (result) {
      this.getData();
      this.notificationService.newNotification({
        message: 'La commande a bien été annulée',
        state: 'success'
      })
    }
    else {
      this.notificationService.newNotification({
        message: "Une erreur est survenue, l'annulation de la commande n'a pas pu aboutir",
        state: 'error'
      })
    }
  }


  public export(type: string): void {
    let params: GetParams<IOrder> = { filter: {} };
    if (type == 'search' && this.selectedStatus) {
      params.filter.status = this.selectedStatus
    }
    let total = this.totalData
    if (type == 'search') {
      params.filter = this.getParams.filter
      params.search = this.getParams.search
      params.sort = this.getParams.sort
      this.addFilterOnOtherParams(params);
      total = this.currentTotalData
    }
    this.actionToggleOpen = false;
    this.fullLoader = true;
    this.exportToggleOpen = false;
    this.provider.exportCSV(this.event._id, params).subscribe(result => {
      this.fullLoader = false;
      this.filesHandler.downloadFile(result, `${total} Commande${total > 1 ? 's' : ''}`);
    });
  }

  public addOrder(): void {
    this.goTo('creer')
  }

  @HostListener('document:click') clickDoc(): void {
    this.actionToggleOpen = false;
    this.exportToggleOpen = false;
  }

}
