import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { IEvent, IEventPublishableStatus } from 'src/app/models/event.model';
import { IEventsStats, IEventStats, StatsDateSelection } from 'src/app/models/stats.model';
import { KeysOfType } from 'src/app/models/type.definition';
import { ReactiveProvider } from '../reactive-provider';
import { APIListResponse, GetParams } from "src/app/models/type.definition";

@Injectable()
export class EventService extends ReactiveProvider<IEvent> {
	protected url = "events";

	selectEvent(event: IEvent) {
		this.selected$.next(event);
	}

	public getAllStats(): Observable<IEventsStats> {
		return this.httpClient.get<IEventsStats>(this.getUrl("stats/general")).pipe(map(stats => {
			this.deleteIfNaN(stats, 'percentDiffCostPriceLastDay');
			this.deleteIfNaN(stats, 'percentDiffOrderLastDay');
			this.deleteIfNaN(stats, 'percentDiffParticipantLastDay');
			this.deleteIfNaN(stats, 'percentDiffSellingPriceLastDay');
			stats.costPriceLastDay /= 100;
			stats.sellingPriceLastDay /= 100;
			return stats
		}));
	}

	public getEventStats(id: string, dates: (string|null)[] = null): Observable<IEventStats> {
		if (!dates) {
			dates = [null, null]
		}
		return this.httpClient.get<IEventStats>(this.getUrl(`stats/${id}`), {
			params: {
				from: dates[0],
				to: dates[1]
			}
		}).pipe(map(stats => {
			stats.costPrice /= 100;
			stats.sellingPrice /= 100;
			stats.tableWidgets = stats.tableWidgets.map(widget => {
				widget.totalSellingPrice /= 100
				widget.totalCostPrice /= 100
				return widget
			})
			stats.distribution = function (distribution: any = stats.distribution): IEventStats['distribution'] {
				const final: IEventStats['distribution'] = [];
				Object.keys(distribution).map(cat => {
					Object.keys(distribution[cat].ticketTypes).map(ticket => {
						final.push({
							totalPrice: distribution[cat].ticketTypes[ticket].price,
							ticketTypeCategory: cat,
							name: ticket
						})
					})
				});
				return final;
			}()
			return stats
		}));
	}

	public canBePublished(id: string): Observable<IEventPublishableStatus> {
		return this.httpClient.get<IEventPublishableStatus>(this.getUrl(`publishable/${id}`));
	}

	public deleteIfNaN(stats: IEventsStats, key: KeysOfType<IEventsStats, string>): void {
		const number = parseInt(stats[key]);
		if (isNaN(number)) {
			stats[key] = null;
		}
	}

	public duplicate(id: string, name: string): Observable<any> {
		return this.httpClient.post<any>(this.getUrl(`duplicate/${id}`), {name});
	}

	public createWorkspace(): Observable<any> {
		return this.httpClient.get<any>(this.getUrl(`seating-plan/create-workspace`));
	}

	public getSeatingPlan(name: string = null, workspace: boolean = false, eventId = null): Observable<any> {
        const filter: GetParams<IEvent>['filter'] = { };
        filter.name = name;
        filter.workspace = workspace;
        filter.eventId = eventId;
		return this.httpClient.get<any>(this.getUrl(`seating-plan/list`, { filter }));
	}

	public getEventSeatingPlanReports(_eventId): Observable<any> {
		return this.httpClient.get<any>(this.getUrl(`seating-plan/reports/${_eventId}`));
	}
}
