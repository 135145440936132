import { AbstractControlOptions, FormArray, FormControl, FormGroup, ValidationErrors, ValidatorFn } from "@angular/forms";
import { IOrganisation } from "./organisation.model";

export interface Storage {
  token: string;
  organisationId: string;
  adminInOrganization: string;
}

export interface RouteType {
  route: string;
  fromRoot?: boolean;
  title: string;
  icon: string;
  active: boolean;
  displaySubmenu: boolean;
  submenu: RouteType[];
  forAdmin?: boolean;
}

export interface DateInterval {
  startDate?: Date;
  endDate?: Date;
  beginDate?: Date;
}

export interface GetParams<T> {
  template?: 'base' | 'full';
  select?: string[];
  filter?: { [key in keyof T | any]?: any};
  populate?: (keyof T | any)[];
  sort?: [string, number][];
  page?: number;
  limit?: number;
  perPage?: number;
  search?: string;
  pdfTemplate?: 'ticket' | 'badge';
}

export const DefaultGetParams = () => ({
  select: [],
  filter: {},
  populate: [],
  search: null
})

export interface APIListResponse<T> extends APIResponse {
  data: T[],
  pager: {
    page: number,
    pages: number
  }
  total: number
}

export interface APICreateResponse extends APIResponse {
  _id?: string;
  name?: string;
}

export interface APIResponse {
  msg?: string
  status?: 'success' | 'error'
  message?: string
}

export interface APIError {
  status: number;
  error: string | APIErrorExtend;
  message: string
}

export interface APIErrorExtend {
  msg: string
  codeError?: number
  data?: any
}

export interface TableOptions<T = null> {
  title?: string;
  class?: string;
  checkbox?: boolean;
  subClass?: string;
  subClass2?: string;
  subClass3?: string;
  sort?: boolean;
  key?: string;
  otherKey?: string;
  isArray?: boolean;
  subKey?: string;
  subKey2?: string;
  classKey?: string;
  link?: boolean;
  target?: "_blank" | "_self" | "_parent" | "_top";
  sublist?: boolean;
  toggleBox?: boolean;
  hover?: boolean,
  hoverKey?: string,
  tooltip?: boolean,
  tooltipKey?: string,
  callback?: (data: T) => void;
  sortMethod?: (order: number) => void;
  if?: (data: T) => boolean;
  hide?: boolean;
  toggle?: {
    title?: string,
    class?: string,
    if?: (data: T) => boolean;
    method?: Function
  }[]
}

export type FormOf<T> = { [key in keyof T]: FormControl | FormGroup | FormArray | FormOf<T[keyof T]> };

export type KeysOfType<T, TProp> = { [P in keyof T]: T[P] extends TProp ? P : never }[keyof T];

export type ValidatorsOf<T> = { [key in keyof T]: [(T[keyof T] | undefined), ...Array<ValidatorFn[] | AbstractControlOptions[]>] };

export type OrganisationToken = {
  token: string,
  roles: string[],
  organisationIds: IOrganisation[],
  organisationId: string
}

export type ImageChangeState = {
  load: boolean,
  changed: boolean
}

export type ImagesChangeState = {
  [keys: string]: ImageChangeState
}

export type StripeTpeToken = {
  token: string
}