import { Component, HostListener, OnInit } from '@angular/core';
import { FormFromEventComponent } from 'src/app/elements/form-component';
import { TicketTypeService, EventSiteService } from 'src/app/providers';
import { GetEvent } from '../../getEvent';
import { FormControl, Validators } from '@angular/forms';
import { ITicketType } from 'src/app/models/ticket.model';
import { IMedia } from 'src/app/models/media.model';
import * as config from 'src/config.json';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { base64ToFile } from 'ngx-image-cropper';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-event-create-exclusive-content',
  templateUrl: './event-create-exclusive-content.component.html',
  styleUrls: ['./event-create-exclusive-content.component.scss'],
  providers: [GetEvent]
})
export class EventCreateExclusiveContentComponent extends FormFromEventComponent<IMedia> implements OnInit {
  public dataName = 'Contenu exclusif';
  public loadingImage: string[] = [];
  public ticketTypes: ITicketType[] = [];
  public dropdownTickets: boolean = false;
  public checkedTicketTypes: string[] = [];

  constructor(
    protected formProvider: EventSiteService,
    protected ticketTypeService: TicketTypeService,
    private sanitizer: DomSanitizer,
    protected getEvent: GetEvent) {
    super();
  }

  protected async onInit(): Promise<void> {
    this.loaded = true;

    this.ticketTypes = await this.getListFromEventOf(this.ticketTypeService, {
      select: ['name', 'ticketTypeCategoryId'],
      populate: ['ticketTypeCategoryId'],
      page: 0,
    });
  }

  protected initForm(): void {
    this.mainForm = this.createFormGroup({
      name: new FormControl(this.data?.name, Validators.required),
      type: 'eventId',
      typeId: this.event._id,
      category: 'exclusiveContent',
      ticketTypeIds: new FormControl([]),
      upl: []
    });
  }

  public async submitForm(): Promise<void> {
    let form = this.mainForm.value;
    form.ticketTypeIds = this.checkedTicketTypes;

    let result = await this.createMedia(form.upl, 'eventId', null, {
      name: form.name,
      category: form.category,
      ticketTypeIds: form.ticketTypeIds,
      typeId: form.typeId
    });

    if (result) {
      this.loadingState$.next();
      this.router.navigate(['evenements/' + this.event._id + '/exclusive-content']);
    }
  }

  public changeImage(key: string, file: File): void {
    this.setValue(['upl'], file);
  }

  public checkElement(
    id: string,
    value: boolean,
    arrayKey:
      | 'checkedTicketTypes'
      | 'checkedOperators'
      | 'checkedSessions'
      | 'checkedAddons'
  ): void {
    const inArray = this[arrayKey].includes(id);
    if (value && !inArray) {
      this[arrayKey].push(id);
    } else if (!value && inArray) {
      this[arrayKey] = this[arrayKey].filter((_) => _ !== id);
    }
  }

  public elementChecked(
    id: string,
    arrayKey:
      | 'checkedTicketTypes'
      | 'checkedOperators'
      | 'checkedSessions'
      | 'checkedAddons'
  ): boolean {
    return this[arrayKey].includes(id) || null;
  }

  public getElementName(
    names: { name?: string }[],
    type: 'tickets' | 'sessions' | 'operateurs' | 'addons' | 'produits'
  ): string {
    if (!names.length) {
      return 'Séléctionner des ' + type;
    } else {
      const fullString = names.map((_) => _.name).join(', ');
      return fullString.length < 80
        ? fullString
        : `${fullString.substring(0, 77)} ...`;
    }
  }

  public getTicketsName(): string {
    return this.getElementName(
      this.ticketTypes.filter((_) => this.checkedTicketTypes.includes(_._id)),
      'tickets'
    );
  }

}
