import { Component, HostListener, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FormFromEventComponent } from 'src/app/elements/form-component';
import { IAddon } from 'src/app/models/addon.model';
import { ITax } from 'src/app/models/tax.model';
import { ITicketType } from 'src/app/models/ticket.model';
import { AddonService, TaxService, TicketTypeService } from 'src/app/providers';
import { GetEvent } from '../../../getEvent';

@Component({
  selector: 'app-event-ticketing-create-addon',
  templateUrl: './event-ticketing-create-addon.component.html',
  styleUrls: ['./event-ticketing-create-addon.component.scss'],
  providers: [GetEvent],
})
export class EventTicketingCreateAddonComponent extends FormFromEventComponent<IAddon> {
  public dataName = 'Addon';
  public ticketTypes: ITicketType[];
  public taxes: ITax[];
  public checkedTicketTypes: string[] = [];
  public dropDown: boolean = false;
  public defaultTax: string;

  @HostListener('document:click') clickDoc(): void {
    this.dropDown = false;
  }

  constructor(
    protected formProvider: AddonService,
    protected ticketTypeService: TicketTypeService,
    protected taxService: TaxService,
    protected getEvent: GetEvent
  ) {
    super();
  }

  async onInit(): Promise<void> {
    this.ticketTypes = await this.getListFromEventOf(this.ticketTypeService, {
      filter: { isProduct: false },
      select: ['name', 'ticketTypeCategoryId'],
      populate: ['ticketTypeCategoryId'],
      perPage: 500,
    });
    this.taxes = await this.getListOf(this.taxService, 'data');
    this.defaultTax = this.event.ticketing.taxId?._id;
    await this.getDataToEdit('addonId', { template: 'full' });
    if (this.data) {
      this.checkedTicketTypes = this.data.ticketTypeIds.map((_) => _._id);
    }
    this.loaded = true;
  }

  initForm(): void {
    const defaultTax = this.data?.taxId?._id || this.defaultTax || null;
    console.log('defaultTax', defaultTax);
    console.log(this.data.stocks);

    this.mainForm = this.createFormGroup({
      name: new FormControl(this.data?.name, [Validators.required, Validators.maxLength(40)]),
      description: new FormControl(this.data?.description),
      //taxId: new FormControl(this.data?.taxId._id, Validators.required),
      taxId: new FormControl(defaultTax, Validators.required),
      eventId: this.event._id,
      price: new FormControl(
        this.data ? this.data.price / 100 : null,
        Validators.required
      ),
      stocks: {
        quota: new FormControl(this.data?.stocks?.quota || null),
      },
    });
  }

  getValue<K extends keyof IAddon>(
    key: K,
    defaultValue: IAddon[K] = null
  ): IAddon[K] {
    return this.checkIfSet(key) ? this.data[key] : defaultValue;
  }

  checkIfSet(key: keyof IAddon): boolean {
    return this.data && ![null, undefined].includes(this.data[key]);
  }

  async submitForm(): Promise<void> {
    const form = this.mainForm.value;
    form.ticketTypeIds = this.checkedTicketTypes;
    form.price *= 100;
    if (this.mainForm.value.ticketTypeIds.length == 0) {
      this.mainForm.value.ticketTypeIds = this.ticketTypes.map(
        (ticket: ITicketType) => ticket._id
      );
    }
    await this.createOrUpdate(this.mainForm.value);
  }

  public checkTicket(id: string, value: boolean): void {
    const inArray = this.checkedTicketTypes.includes(id);
    if (value && !inArray) {
      this.checkedTicketTypes.push(id);
    } else if (!value && inArray) {
      this.checkedTicketTypes = this.checkedTicketTypes.filter((_) => _ !== id);
    }
  }

  public ticketChecked(id: string): boolean {
    return this.checkedTicketTypes.includes(id) || null;
  }

  public getTicketsName(): string {
    const names = this.ticketTypes.filter((_) =>
      this.checkedTicketTypes.includes(_._id)
    );
    if (!names.length) {
      return 'Séléctionner des tickets';
    } else {
      const fullString = names.map((_) => _.name).join(', ');
      return fullString.length < 80
        ? fullString
        : `${fullString.substring(0, 77)} ...`;
    }
  }
}
