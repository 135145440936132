<section id="form_page" class="container loading_container" [ngClass]="{ 'loaded' : mainForm }">
    <div class="loading_content">
        <div id="page_header">
            <h1>Équipe <span>({{members}} membre{{members > 1 ? 's' : ''}})</span></h1>
        </div>
        <div id="form_wrapper">
            <h2>{{data ? 'Modifier' : 'Ajouter'}} un membre</h2>

            <form *ngIf="mainForm" [formGroup]="mainForm">

            <!-- <app-file-input class="form_item" text="Ajoutez une photo de profil au membre" (imageChange)="uploadFile($event)"></app-file-input> -->
            <div class="form_fields">
                <div class="form_cols">
                    <div class="form_item half">
                        <label for="firstName">Prénom</label>
                        <input *ngIf="!data" id="firstName" type="text" formControlName="firstName">
                        <div *ngIf="data" class="fake_input disabled">{{data.userId.firstName}}</div>
                    </div>
                    <div class="form_item half">
                        <label for="lastName">Nom</label>
                        <input *ngIf="!data" id="lastName" type="text" formControlName="lastName">
                        <div *ngIf="data" class="fake_input disabled">{{data.userId.lastName}}</div>
                    </div>
                </div>
                <div class="form_item">
                    <label for="email">E-mail</label>
                    <input *ngIf="!data" id="email" type="email" formControlName="email">
                    <p *ngIf="!data" class="email_info">Si l'email du compte que vous voulez ajouter existe déjà il sera
                        rattaché à
                        votre organisation et les champs personnels renseignés ne seront pas pris en compte</p>
                    <div *ngIf="data" class="fake_input disabled">{{data.userId.email}}</div>
                </div>

                    <div *ngIf="!data" class="form_cols">
                        <div class="form_item half">
                            <label for="password">Mot de passe</label>
                            <div class="input_wrap password">
                                <input #pwd1 (keyup)="checkPassword(pwd1.value, pwd2/value)" id="password"
                                    [attr.type]="showPwd ? 'text' : 'password'" formControlName="password">
                                <svg role="img" title="eye" class="eye-icon" [ngClass]="{'active': showPwd}"
                                    (click)="showPwd = !showPwd">
                                    <use href="assets/images/sprite.svg#icon-eye"></use>
                                </svg>
                            </div>
                        </div>
                        <div class="form_item half">
                            <label for="password2">Confirmation du mot de passe</label>
                            <div class="input_wrap password">
                                <input #pwd2 (keyup)="checkPassword(pwd1.value, pwd2.value)" id="password2"
                                    [attr.type]="showPwd2 ? 'text' : 'password'">
                                <svg role="img" title="eye" class="eye-icon" [ngClass]="{'active': showPwd2}"
                                    (click)="showPwd2 = !showPwd2">
                                    <use href="assets/images/sprite.svg#icon-eye"></use>
                                </svg>
                            </div>
                        </div>
                    </div>
                    <div class="form_cols">
                        <div class="form_item half">
                            <label for="role">Rôle</label>
                            <div class="input_wrap select">
                                <select id="role" formControlName="role">
                                    <option value="reader">Lecteur</option>
                                    <option value="organizer">Organisateur</option>
                                    <option value="collaborator">Collaborateur</option>
                                    <option value="operator">Opérateur</option>
                                </select>
                                <svg role="img" title="chevron" class="icon-chevron">
                                    <use href="assets/images/sprite.svg#icon-chevron"></use>
                                </svg>
                            </div>
                        </div>
                    </div>
                    <h3>Événements à gérer</h3>
                    <div class="form_cols">
                        <div class="form_item half">
                            <div class="input_wrap select multiple">
                                <span class="select_multiple_toggle"
                                    (click)="dropDown = !dropDown; $event.stopPropagation()">
                                    {{getEventsName()}}
                                </span>
                                <svg role="img" title="chevron" class="icon-chevron">
                                    <use href="assets/images/sprite.svg#icon-chevron"></use>
                                </svg>
                                <div class="dropdown multiple" *ngIf="dropDown" (click)="$event.stopPropagation()">
                                    <ul>
                                        <li *ngFor="let event of events; let i = index">
                                            <input id="event_{{i}}" type="checkbox" [attr.checked]="eventChecked(event._id)"
                                                (change)="checkEvent(event._id, $event.target.checked)" />
                                            <label for="event_{{i}}">
                                                {{event.name}}
                                                <svg role="img" title="check" class="check-icon">
                                                    <use href="assets/images/sprite.svg#icon-check"></use>
                                                </svg>
                                            </label>
                                        </li>
                                    </ul>
                                    <div class="dropdown_action">
                                        <app-button (clickEvent)="dropDown = false" text="Sélectionner"
                                            [style]="'plain full'"></app-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form_actions right">
                    <app-button [disabled]="mainForm.invalid || (!data && !passwordsOk)" [loadingOff]="loadingState$" type="submit"
                        text="{{data ? 'Modifier' : 'Ajouter'}}" (clickEvent)="submitForm()" [style]="'plain'"></app-button>
                </div>
                <app-popup *ngIf="error != null" title="Une erreur est survenue" message="{{error}}" popupOpened="true"
                    (backEvent)="error = null"></app-popup>
            </form>
        </div>
    </div>
</section>