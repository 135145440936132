import { Component, OnInit } from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';

import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { IEvent } from 'src/app/models/event.model';
import { GetParams } from 'src/app/models/type.definition';
import { AuthService, EventService } from 'src/app/providers';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { NotificationService } from 'src/app/services/notification.service';
import { UpdateService } from 'src/app/services/update.service';
import { GetEvent } from '../../getEvent';
import * as config from 'src/config.json';
import { MatChipInputEvent } from '@angular/material/chips';

@Component({
  selector: 'app-event-ticketing-settings',
  templateUrl: './event-ticketing-settings.component.html',
  styleUrls: ['./event-ticketing-settings.component.scss'],
  providers: [GetEvent],
})
export class EventTicketingSettingsComponent implements OnInit {
  private _subscription = new Subscription();
  public event: IEvent;
  public error: string;
  public isAdmin: boolean;
  public hasAddonLimit: boolean = false;
  public hasAddonMinimum: boolean = false;
  public loadingState$: Subject<void> = new Subject<void>();
  public ticketingSettingForm: FormGroup;
  public currentUrl;
  public bookingTimeOptions: [number, string, number][] = [
    [5, 'minutes', 300],
    [15, 'minutes', 900],
    [30, 'minutes', 1800],
    [45, 'minutes', 2700],
    [1, 'heure', 3600],
  ];
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  emailList: string[] = [];

  constructor(
    private errorHandler: ErrorHandlerService,
    private getEvent: GetEvent,
    private update: UpdateService,
    private formBuilder: FormBuilder,
    private eventService: EventService,
    private notification: NotificationService,
    private authService: AuthService,
    private router: Router
  ) {}

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  async ngOnInit() {
    this.currentUrl = this.router.url;
    await this.getCurrentEvent();
    this.isAdmin = this.authService.isAdmin;
    this.initForm();
  }

  async getCurrentEvent(reset = false): Promise<void> {
    const eventOptions: GetParams<IEvent> = {
      select: ['name', 'ticketing', 'emailAlerts', 'commission'],
    };
    this.event = await this.getEvent.get(eventOptions, reset);
    this.hasAddonLimit = (this.event.ticketing?.limitAddonAmount) ? true : false
    this.hasAddonMinimum = (this.event.ticketing?.minimumAddonAmount) ? true : false
  }

  initForm() {
    this.ticketingSettingForm = this.formBuilder.group({
      type: new FormControl(null),
      commission: this.formBuilder.group({
        flat: new FormControl(this.event.commission.flat / 100),
        percentage: new FormControl(this.event.commission.percentage),
      }),
      ticketing: this.formBuilder.group({
        quota: new FormControl(this.event.ticketing?.quota),
        date: this.formBuilder.group({
          startDate: new FormControl(this.event.ticketing?.date.startDate),
          endDate: new FormControl(this.event.ticketing?.date.endDate),
          timezone: this.event.ticketing?.date.timezone,
        }),
        termAndConditions: this.formBuilder.group({
          url: new FormControl(
            this.event.ticketing?.termAndConditions?.url ||
              config.termAndConditionsUrl
          ),
          text: new FormControl(
            this.event.ticketing?.termAndConditions?.text ||
              config.termAndConditionsText
          ),
        }),
        bookingTime: new FormControl(this.event.ticketing?.bookingTime),
        hasBadge: new FormControl(this.event.ticketing?.hasBadge),
        inOut: new FormControl(this.event.ticketing?.inOut),
        limitAddonAmount: new FormControl(this.event.ticketing?.limitAddonAmount || 0),
        minimumAddonAmount: new FormControl(this.event.ticketing?.minimumAddonAmount || 0),
      }),
      emailAlerts: this.formBuilder.group({
        eachOrder: new FormControl(this.event.emailAlerts?.eachOrder),
        dailySummary: new FormControl(this.event.emailAlerts?.dailySummary),
        weeklySummary: new FormControl(this.event.emailAlerts?.weeklySummary),
        customMailingList: new FormControl(
          this.event.emailAlerts?.customMailingList
        ),
      }),
      mailingList: this.formBuilder.array(this.event.emailAlerts.mailingList.map((email) => {
        return this.formBuilder.group({
          value: email,
          valid: true
        })
      }) || []),
    });


    if (this.event?.mailingList && this.event?.mailingList?.length !== 0) {
      for (let email of this.event?.mailingList) {
        let emailFormGroup = this.formBuilder.group({
          value: email,
          valid: true,
        });
        this.emailsOrderForm.push(emailFormGroup);
      }
    }

  }

  changeAddOnLimit(value: boolean): void {
    this.hasAddonLimit = !this.hasAddonLimit;
    if (!value) {
      this.ticketingSettingForm.get('ticketing').get('limitAddonAmount').patchValue(0)
    }
  }

  changeAddOnMinimum(value: boolean): void {
    this.hasAddonMinimum = !this.hasAddonMinimum;
    if (!value) {
      this.ticketingSettingForm.get('ticketing').get('minimumAddonAmount').patchValue(0)
    }
  }

  private validateEmail(email: string) {
    var re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    let testEmail = this.validateEmail(value.trim());
    if (testEmail) {
      if (value.trim()) {
        if (!this.emailList.includes(value.trim())) {
          this.emailList.push(value.trim());
          let emailFormGroup = this.formBuilder.group({
            value: value.trim(),
          });
          this.emailsOrderForm.push(emailFormGroup);
          if (input) {
            input.value = '';
          }
        }
      }
    }
  }

  get emailsOrderForm() {
    return <FormArray>this.ticketingSettingForm.get('mailingList');
  }

  removeEmail(emailIndex: number) {
    this.emailList.splice(emailIndex, 1);
    this.emailsOrderForm.removeAt(emailIndex);
  }

  goBack(): void {
    this.router.navigate([`evenements/${this.event._id}/billetterie`]);
  }

  updateEvent() {
    const date = { ...this.ticketingSettingForm.value.ticketing.date };
    const formData = {
      ...this.ticketingSettingForm.value,
      emailAlerts: {
        ...this.ticketingSettingForm.value.emailAlerts,
        mailingList: this.ticketingSettingForm.value.mailingList
        .map((elem) => elem.value)
        .filter((elem) => elem),
      },
    };
    formData.ticketing = this.update.form(
      formData.ticketing,
      this.event.ticketing
    );

    if (!this.isAdmin) {
      delete formData.commission
    }

    formData.ticketing.date = date;
    if (formData.commission) {
      formData.commission = {
        flat: formData.commission.flat * 100,
        percentage: formData.commission.percentage,
      };
    }
    this._subscription.add(
      this.eventService.update(this.event._id, formData).subscribe(
        async (result) => {
          this.loadingState$.next();
          await this.getCurrentEvent(true);
          this.eventService.selected$.next(this.event);
          this.notification.newNotification({
            message: 'Billetterie mise à jour avec succès',
            state: 'success',
          });
        },
        (err: any) => {
          this.error = this.errorHandler.getError(err);
          this.notification.newNotification({
            message: this.error,
            state: 'error',
          });
          this.loadingState$.next();
        }
      )
    );
  }

  changeDates(dates) {
    const startDate = dates.startDate;
    const endDate = dates.endDate;
    const dateForm = this.ticketingSettingForm
      .get('ticketing')
      .get('date') as FormGroup;
    dateForm.patchValue({ startDate, endDate });
  }

  selectTimeZone(timezone: string) {
    const date = this.ticketingSettingForm
      .get('ticketing')
      .get('date') as FormGroup;
    date.patchValue({ timezone });
  }
}
