import { Data } from "./data";
import { EditableEventData } from "./editable-data";
import { IEvent } from "./event.model";
import { IOrganisation } from "./organisation.model";
import { IPromoCode } from "./promo-code.model";
import { ITax } from "./tax.model";
import { ITicket } from "./ticket.model";
import { IWidget } from "./widget.model";

export interface IOrder extends EditableEventData {
  orderNumber?: string;
  isInvitation?: boolean;
  name?: string;
  origin?: string;
  status?: OrderStatus;
  paymentStatus?: OrderPaymentStatus;
  translatedStatus?: TranslatedOrderStatus;
  translatedOrigin?: TranslatedOriginStatus;
  paymentMethod?: string;
  date?: string;
  staggered?: 'Oui' | 'Non';
  eventName?: string;
  amount?: string;
  translatedMethod?: string;
  sendingMethod?: 'same' | 'separate';
  totalPrice?: {
    sellingPrice?: number,
    costPrice?: number,
    taxId?: ITax,
    promoCodeId?: IPromoCode,
    commission?: {
      flat?: number,
      percentage?: number,
      calculated?: number
    },
    currency?: {
      isoCode?: string,
      value?: number
    }
  };
  payments?: IOrderPayment[];
  payment?: {
    referer?: 'web' | 'ticketoffice',
    method?: 'creditCard' | 'cash' | 'check' | ''
    tracker?: string,
    gateway?: string,
    transactions?: {
      gateway?: string,
      type?: 'charge' | 'refund',
      amount?: number,
      status?: 'draft' | 'paid' | 'partiallyPaid' | 'paymentRefused'
    }[],
    totalPaid?: number
  };
  eventId?: IEvent;
  eventWidgetId?: IWidget;
  organisationId?: IOrganisation;
  token?: string;
  expire?: Date;
  invoiceData?: InvoiceData;
  isProduct?: boolean;
  type?: string;
  tickets?: ITicket[];
}

export interface IOrderPayment extends Data {
  amount: number;
  gateway?: string
  orderId?: IOrder
  method?: string
  customMethod?: {
    label: string,
    value: string
  }
  remoteId?: string;
  remoteStatus?: string;
  refundedAmount?: number;
  referer?: "web" | "ticketoffice" | "backoffice";
  status?: string
}

export type InvoiceData = {
  civility?: string,
  company?: string,
  email?: string,
  lastName?: string,
  firstName?: string,
  address?: string,
  address2?: string,
  zipCode?: string,
  city?: string
}

type OrderPaymentStatus = 'paid' | 'notPaid' | 'partiallyPaid' | 'paymentRefused' | 'refunded';
//type OrderStatus_old = 'draft' | 'abandoned' | 'paid' | 'notPaid' | 'partiallyPaid' | 'invitation' | 'paymentRefused' | 'refunded';
type OrderStatus = 'draft' | 'pending' | 'completed' | 'canceled'
type TranslatedOrderStatus = 'Brouillon' | 'En attente' | 'Complétée' | 'Annulée'
type TranslatedOrderPaymentStatus = 'Payée' | 'Non payée' | 'Partiellement payée' | 'Paiement refusé' | 'Remboursée'


/**
 * Method to translate the status
 * @param value
 */
export function translateStatus(value: OrderStatus): TranslatedOrderStatus {
  switch (value) {
    case 'draft': return 'Brouillon';
    case 'pending': return 'En attente';
    case 'completed': return 'Complétée';
    case 'canceled': return 'Annulée';
  }
}

/**
 * Method to translate the status
 * @param value
 */
export function translatePaymentStatus(value: OrderPaymentStatus): TranslatedOrderPaymentStatus {
  switch (value) {
    case 'paid': return 'Payée';
    case 'notPaid': return 'Non payée';
    case 'partiallyPaid': return 'Partiellement payée';
    case 'paymentRefused': return 'Paiement refusé';
    case 'refunded': return 'Remboursée';
  }
}

type TranslatePaymentMethodStatus = 'Carte Bancaire' | 'Espèce' | 'Chèque' | 'Gratuit' | 'Invitation' | 'Imp. stock';

/**
 * Method to translate the payment methods
 * @param value
 */
export function translatePaymentMethod(value: string): TranslatePaymentMethodStatus {
  switch (value) {
    case 'creditCard': return 'Carte Bancaire';
    case 'free': return 'Gratuit';
    case 'cash': return 'Espèce';
    case 'check': return 'Chèque';
    case 'invitation': return 'Invitation';
    case 'stockImpression': return 'Imp. stock';
  }
}

type TranslatedOriginStatus = 'Web' | 'Guichet' | 'Invitation' | 'Impression de stock';

/**
 * Method to translate the order origin
 * @param value
 */
export function translatedOrigin(value: string): TranslatedOriginStatus {
  switch (value) {
    case 'web': return 'Web';
    case 'ticketOffice': return 'Guichet';
    case 'invitation': return 'Invitation';
    case 'stockImpression': return 'Impression de stock';
    default: return 'Web'
  }
}
