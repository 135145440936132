import { Injectable, OnDestroy } from '@angular/core';
import {
  CurrencyService,
  EventService,
  EventSiteService,
  FormService,
  TicketTypeCategoryService,
  TicketTypeService,
} from 'src/app/providers';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { GrowthService } from 'src/app/services/growth.service';
import { IEvent, translateStatus } from 'src/app/models/event.model';
import {
  APIListResponse,
  GetParams,
  KeysOfType,
} from 'src/app/models/type.definition';
import { Provider } from 'src/app/providers/provider';
import { EditableEventData } from 'src/app/models/editable-data';
import { ICurrency } from 'src/app/models/currency.model';
import {
  updateOrCreateEmptyCategory,
  ITicketType,
} from 'src/app/models/ticket.model';

@Injectable()
export class GetEvent implements OnDestroy {
  _subscription = new Subscription();

  constructor(
    private eventService: EventService,
    private route: ActivatedRoute,
    private growthService: GrowthService,
    private currencyService: CurrencyService,
    private formsService: FormService,
    private siteService: EventSiteService,
    private ticketTypeCategoryService: TicketTypeCategoryService,
    private ticketTypeService: TicketTypeService
  ) {}

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  async get(options: GetParams<IEvent> = {}, reset = false): Promise<IEvent> {
    options.select = options.select || [];
    options.select.push('name');
    options.select.push('dates');
    options.select.push('organisationId');
    options.select.push('useRoomPlacement');
    options.select.push('ticketing');
    options.select.push('bookType');
    options.select.push('ticketTypeCategories');
    let event = await this.toPromise(this.eventService.selected$);
    if (!event || reset) {
      const id = (await this.toPromise(this.route.params)).id;
      event = await this.toPromise(this.eventService.getById(id, options));
      this.eventService.selectEvent(event);
    }
    await this.getAllEmbeddedData(event, options);
    event = await this.growthService.addElementsOnObject(
      options,
      event,
      this.eventService,
      this.getEventSelectKey,
      this.toPromise
    );
    if (options.select.includes('status')) {
      event.translateStatus = translateStatus(event.status);
    }
    if (
      !event.ticketing?.defaultCurrencyId.createdAt
    ) {
      await this.getCurrency(event);
    }
    return event;
  }

  async getAllEmbeddedData(event: IEvent, options: GetParams<IEvent>) {
    if (this.ifAskedAndDoesntExist(event, options, 'forms')) {
      await this.getEmbeddedData(event, 'forms', this.formsService, true);
    }
    if (this.ifAskedAndDoesntExist(event, options, 'site')) {
      await this.getEmbeddedData(event, 'site', this.siteService);
    }

    if (this.ifAskedAndDoesntExist(event, options, 'ticketTypeCategories')) {
      await this.getEmbeddedData(
        event,
        'ticketTypeCategories',
        this.ticketTypeCategoryService,
        true,
        async (event) => {
          event.ticketTypeCategories = event.ticketTypeCategories.map(
            (category) => ({ ...category, ticketTypes: [] })
          );
          updateOrCreateEmptyCategory(event);
          const getParams: GetParams<ITicketType> = {
            filter: { eventId: event._id, isProduct: false },
            sort: [['order', 1], ['createdAt', 1]],
            perPage: 500,
          };
          const tickets = await this.toPromise(
            this.ticketTypeService.getList(getParams)
          );
          (tickets?.data || []).forEach((ticket) => {
            ticket.price = ticket.price < 0 ? 0 : ticket.price;
            ticket.stringPrice = ticket.price ? ticket.price + '€' : 'Gratuit';
            const category = event.ticketTypeCategories.find((_) => {
              return (
                _._id == ticket.ticketTypeCategoryId?._id ||
                (!_._id && !ticket.ticketTypeCategoryId)
              );
            });
            category?.ticketTypes.push(ticket);
            // category.ticketTypes.push({ ...ticket, order: (category.ticketTypes[category.ticketTypes.length - 1]?.order + 1) || 0 })
          });
          const noCat = event.ticketTypeCategories.find((_) => _?._id == null);
          if (noCat && !noCat.ticketTypes?.length) {
            event.ticketTypeCategories.pop();
          }
        }
      );
    }
    if (this.ifAskedAndDoesntExist(event, options, 'productTypeCategories')) {
      await this.getEmbeddedData(
        event,
        'productTypeCategories',
        this.ticketTypeCategoryService,
        true,
        async (event) => {
          event.productTypeCategories = event.productTypeCategories.map(
            (category) => ({ ...category, ticketTypes: [] })
          );
          updateOrCreateEmptyCategory(
            event,
            undefined,
            'productTypeCategories'
          );
          const getParams: GetParams<ITicketType> = {
            filter: { eventId: event._id, isProduct: true },
            perPage: 500,
          };
          const tickets = await this.toPromise(
            this.ticketTypeService.getList(getParams)
          );

          (tickets?.data || []).forEach((ticket) => {
            ticket.price = ticket.price < 0 ? 0 : ticket.price;
            ticket.stringPrice = ticket.price ? ticket.price + '€' : 'Gratuit';
            const category = event.productTypeCategories.find((_) => {
              return (
                _._id == ticket.ticketTypeCategoryId?._id ||
                (!_._id && !ticket.ticketTypeCategoryId)
              );
            });
            category?.ticketTypes.push(ticket);
            // category.ticketTypes.push({ ...ticket, order: (category.ticketTypes[category.ticketTypes.length - 1]?.order + 1) || 0 })
          });
          const noCat = event.productTypeCategories.find((_) => _._id == null);
          if (noCat && !noCat.ticketTypes?.length) {
            event.productTypeCategories.pop();
          }

        },
        { filter: { eventId: event._id, isProduct: true } }
      );
    }
  }

  ifAskedAndDoesntExist(
    event: IEvent,
    options: GetParams<IEvent>,
    key: keyof IEvent
  ): boolean {
    return options.select.includes(key) && !event[key];
  }

  async getCurrency(event: IEvent): Promise<void> {
    const result = await this.toPromise(this.currencyService.getAll());
    const currencies =
      (result as APIListResponse<ICurrency>).data || (result as ICurrency[]);
    this.currencyService.data$.next(currencies);
    event.ticketing.defaultCurrencyId = currencies.find(
      (_) => _._id === event.ticketing.defaultCurrencyId._id
    );
    event.ticketing.defaultCurrencyId.symbol =
      this.currencyService.getCurrencySymbol(event.ticketing.defaultCurrencyId);
  }

  /**
   * Get embedded data of Event
   *
   * @param event
   * @param key
   * @param service
   * @param isArray
   * @param callback
   */
  async getEmbeddedData(
    event: IEvent,
    key: KeysOfType<IEvent, EditableEventData | EditableEventData[]>,
    service: Provider<EditableEventData>,
    isArray = false,
    callback?: (event: IEvent) => Promise<void>,
    options?: GetParams<EditableEventData>
  ): Promise<void> {
    const data = await this.toPromise(
      service.getList(options || { filter: { eventId: event._id, isProduct: false } })
    );
    event[key as string] = isArray ? data?.data || [] : data?.data[0];
    callback && (await callback(event));
  }

  toPromise = <T>(observable: Observable<T>): Promise<T> =>
    new Promise((res) =>
      this._subscription.add(observable.subscribe((_) => res(_)))
    );

  getEventSelectKey(key: string) {
    switch (key) {
      case 'categories':
        return 'categoriesIds';
      case 'country':
        return 'place';
      case 'sessions':
        return 'dates';
      case 'author':
        return 'authorId';
      case 'tax':
      case 'currencies':
      case 'image':
        return 'ticketing';
    }
  }
}
