import { flatten } from '@angular/compiler';
import { Component, HostListener, OnInit } from '@angular/core';
import { moreAnimation } from 'src/app/animations/animations';
import { FormFromEventComponent } from 'src/app/elements/form-component';
import { IEvent } from 'src/app/models/event.model';
import { IFormField } from 'src/app/models/form.model';
import { IMedia } from 'src/app/models/media.model';
import { ITicket } from 'src/app/models/ticket.model';
import { GetParams } from 'src/app/models/type.definition';
import { EventService, FormFieldService, MediaService, OrderService, TicketService } from 'src/app/providers';
import { GetEvent } from '../../getEvent';

@Component({
  selector: 'app-event-participants-detail',
  templateUrl: './event-participants-detail.component.html',
  styleUrls: ['./event-participants-detail.component.scss'],
  providers: [GetEvent],
  animations: [moreAnimation]
})
export class EventParticipantsDetailComponent extends FormFromEventComponent<ITicket> implements OnInit {
  public dataName = "Participant";
  public getEventParams: GetParams<IEvent> = { select: ['ticketing', 'status', 'organisationId'] };
  public name: string;
  public resendConfirmOfOrder: boolean;
  public fullLoader: boolean;
  public addons: string;
  public deleteTicket: boolean;
  public actionToggleOpen: boolean;
  public newFirstName: string;
  public newLastName: string;
  public newEmail: string;
  public editName: boolean = false;
  public editEmail: boolean = false;
  public actionToggle = {
    ref: this,
    links: []
  }

  constructor(
    protected formProvider: TicketService,
    protected getEvent: GetEvent,
    protected formFieldService: FormFieldService,
    protected mediaService: MediaService,
    protected orderService: OrderService
  ) {
    super()
  }

  async onInit() {
    /** @ts-ignore */
    // Evo nft
    const data = await this.getEvent.get({ select: ['bookType'] });
    if (data.bookType != "nft") {
      this.actionToggle.links.unshift({
        title: 'Télécharger le ticket',
        method: this.download,
        class: ''
      },
      {
        title: 'Renvoyer le ticket',
        method: this.resendConfirm,
        class: ''
      },
      {
        title: 'Supprimer',
        class: "red",
        method: this.delete
      })
    }
    // Fin evo
    await this.getDataToEdit('participantId', { populate: ['orderId', 'ticketTypeId', 'scans.controlListId', 'scans.operatorId'] });
    this.name = [this.data.lastName?.toUpperCase(), this.data.firstName].filter(_ => _).join(' ') || 'Non renseigné';
    this.newLastName = this.data.lastName;
    this.newFirstName = this.data.firstName;
    this.newEmail = this.data.email;
    this.data.form = this.data.form.filter(_ => _.id);
    if (this.data.addons.length) {
      this.addons = this.data.addons.map(_ => _.name).join(' / ');
    }
    let allFieldIds = flatten(this.data.form.map(form => {
      const fields = form.fields.map(field => field.formFieldId)
      return {
        formId: form.id,
        fieldIds: fields.filter((value, index) => fields.indexOf(value) === index)
      }
    }));
    const allFields = await this.getAllFields(allFieldIds);
    this.data.form = await Promise.all(this.data.form.map(async form => ({
      id: form.id,
      fields: await Promise.all(form.fields.map(async field => {
        const fullField = allFields.find(fullField => fullField._id == field.formFieldId)
        return {
          label: fullField.label,
          values: await this.getFieldValue(field.values as string | number | string[], fullField)
        }
      }))
    })));
  }

  public async saveNewName(): Promise<void> {
    this.editName = false;
    await this.updateTicketData({ firstName: this.newFirstName, lastName: this.newLastName });
    this.data.firstName = this.newFirstName
    this.data.lastName = this.newLastName
    this.name = [this.data.lastName?.toUpperCase(), this.data.firstName].filter(_ => _).join(' ') || 'Non renseigné';
  }

  public async saveNewEmail(): Promise<void> {
    this.editEmail = false;
    await this.updateTicketData({ email: this.newEmail });
    this.data.email = this.newEmail
  }

  public async updateTicketData(ticketData: ITicket): Promise<void> {
    await this.update(ticketData).toPromise();
  }

  private async getFieldValue(
    value: string | number | string[],
    fullField: IFormField
  ): Promise<string | number | string[] | { name: string, url: string }> {
    if (fullField.type == 'upload') {
      const media = await this.mediaService.getMedia(value as string, { select: ['url', 'name'] }).toPromise();
      return media ? {
        name: media.name || 'Non renseigné',
        url: media.url
      } : 'Non renseigné'
    }
    else {
      if (Array.isArray(value)) {
        return value.length ? value : 'Non renseigné'
      }
      return value || 'Non renseigné'
    }
  }

  getAllFields(allFieldIds: { formId: string, fieldIds: string[] }[]): Promise<IFormField[]> {
    const allFields = [];
    return new Promise(async res => {
      for (let i = 0; i < allFieldIds.length; i++) {
        const fields = await this.getListFromEventOf(this.formFieldService, {
          filter: {
            _id: allFieldIds[i].fieldIds.join(' '),
            formId: allFieldIds[i].formId
          }
        });
        allFields.push(...fields);
        if (i == allFieldIds.length - 1) {
          res(allFields);
        };
      }
    })
  }

  protected initForm(): void { }

  public submitForm(): void { }

  public download(ref = this): void {
    ref.fullLoader = true;
    ref.orderService.streamOrder(ref.data.orderId._id, ref.data.orderId.token).subscribe(result => {
      ref.fullLoader = false;
      ref.filesHandler.downloadFile(result, 'Facture commande n°' + ref.data.orderId.orderNumber)
    }, err => {
      ref.notificationService.newNotification({ state: 'error', message: ref.errorHandler.getError(err) });
      ref.fullLoader = false;
    })
  }

  public delete(ref = this): void {
    ref.deleteTicket = true;
  }

  public deleteOk(): void {
    this.formProvider.update(this.id, { status: 'canceled' }).subscribe(result => {
      this.deleteTicket = false;
      if (result) {
        this.goBack(this.id);
        this.notificationService.newNotification({
          message: 'Ticket supprimé avec succès',
          state: 'success'
        })
      }
      else {
        this.notificationService.newNotification({
          message: 'Une erreur s\'est produite, veuillez réessayer plus tard',
          state: 'error'
        })
      }
    })
  }

  public resendConfirm(ref = this): void {
    ref.resendConfirmOfOrder = true
  }

  public resendConfirmOk(ref = this): void {
    this.orderService.resendAnOrderConfirmation(ref.data.orderId._id).subscribe(_result => {
      this.resendConfirmOfOrder = null
      if (_result) {
        this.notificationService.newNotification({
          message: 'La confirmation a bien été renvoyée',
          state: 'success'
        })
      }
      else {
        this.notificationService.newNotification({
          message: 'Une erreur s\'est produite, veuillez réessayer plus tard',
          state: 'error'
        })
      }
    });
  }

  @HostListener('document:click') clickDoc(): void {
    this.actionToggleOpen = false;
  }

}
