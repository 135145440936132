import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IEvent } from 'src/app/models/event.model';
import { GetEvent } from '../../getEvent';
import { ListFromEventComponent } from 'src/app/elements/list-component';
import { getTranslatedType, IControlList } from 'src/app/models/control-list';
import { ControlListService, TicketTypeService } from 'src/app/providers';
import { GetParams, TableOptions } from 'src/app/models/type.definition';
import { ITicketType } from 'src/app/models/ticket.model';

@Component({
  selector: 'app-event-access-control',
  templateUrl: './event-access-control.component.html',
  styleUrls: ['./event-access-control.component.scss'],
  providers: [GetEvent]
})
export class EventAccessControlComponent extends ListFromEventComponent<IControlList> implements OnInit {
  public dataName = "Contrôle d'accès";
  public currentUrl: string;
  public event: IEvent;
  public getEventParams: GetParams<IEvent> = { filter: {}, select: ['eventCode'] }

  public ticketTypes: ITicketType[];

  public tableOptions: TableOptions[] = [{
    title: "Nom",
    class: "name",
    sort: true,
    key: 'name',
    //sortMethod: order => this.sort('name', order),
  },
  {
    title: "Participants",
    class: "participants",
    sort: true,
    key: 'participants',
    //sortMethod: order => this.sort('participants', order),
  },
  {
    title: "Tarifs / Addons concernés",
    class: "rates",
    sort: false,
    key: 'rates',
    hover: true,
    hoverKey: 'ratesArray'
    //sortMethod: order => this.sort('rates', order),
  },
  {
    title: "Type de tarifs",
    class: "type",
    sort: false,
    key: 'translatedType',
  },
  {
    title: "Operateurs",
    class: "operators",
    sort: true,
    key: 'operators',
    //sortMethod: order => this.sort('operators', order),
  },
  {
    title: "Actif",
    class: "active",
    key: 'isActive',
    toggleBox: true,
    callback: this.changeIsActive
  },
  {
    class: "more",
    toggle: [
      {
        title: 'Éditer',
        method: this.editData
      },
      {
        title: 'Supprimer ce contrôle',
        method: this.deleteData,
        class: 'red'
      }
    ]
  }];

  constructor(
    protected provider: ControlListService,
    protected getEvent: GetEvent,
    protected ticketTypeService: TicketTypeService
  ) {
    super();
  }

  protected async formatElement(list: IControlList): Promise<IControlList> {
    list.participants = `${list.ticketsScanned}/${list.ticketsTotal}`;
    list.rates = 'Tous'
    list.translatedType = getTranslatedType(list);
    if (list.ticketTypeFiltered) {
      if (list.ticketTypeIds.length) {
        list.ratesArray = list.ticketTypeIds.map(_ => _.name);
        list.rates = list.ratesArray.join(', ');
      }
      else if (list.addonIds.length) {
        list.ratesArray = list.addonIds.map(_ => _.name);
        list.rates = list.ratesArray.join(', ');
      }
    }
    const operators = list.operatorIds.length
    list.operators = `${operators} opérateur${operators > 1 ? 's' : ''}`
    return list
  }

  protected setGetParams(): void {
    this.getParams.filter.organisationId = this.event.organisationId._id;
    this.getParams.select = ['name', 'operatorIds', 'rules', 'isActive', 'ticketsTotal', 'ticketsScanned'];
    this.getParams.populate = ['ticketTypeIds', 'addonIds'];
  }

  public createList(): void {
    this.router.navigate([`${this.currentUrl}/creer`]);
  }

  public deleteData(data: IControlList, ref: ListFromEventComponent<IControlList> = this): void {
    ref.elementToDelete = data;
  }

  public deleteOk(): void {
    super.deleteData(this.elementToDelete, this, () => {
      this.elementToDelete = null;
    });
  }

  public async changeIsActive(data: IControlList, ref = this): Promise<void> {
    await ref.provider.update(data._id, { isActive: !data.isActive }).toPromise()
  }

}
