import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl, Validators, FormArray } from '@angular/forms';
import * as config from "src/config.json";
import { AuthService, CountryService, OrganisationService } from 'src/app/providers';
import { FormComponent } from 'src/app/elements/form-component';
import { IOrganisation } from 'src/app/models/organisation.model';
import { APICreateResponse, APIResponse } from 'src/app/models/type.definition';
import { StorageService } from 'src/app/services/storage.service';
import { FileToCreate } from 'src/app/models/media.model';
import { ICountry } from 'src/app/models/country.model';

@Component({
  selector: 'app-create-organisation',
  templateUrl: './create-organisation.component.html',
  styleUrls: ['./create-organisation.component.scss']
})
export class CreateOrganisationComponent extends FormComponent<IOrganisation> {
  public dataName = "Organisation";
  public orgaTypes = config.organisationType;
  public countries: ICountry[];
  protected waitUser = true;

  constructor(
    private countryService: CountryService,
    protected formProvider: OrganisationService,
    protected storageService: StorageService,
    protected authService: AuthService) {
    super()
  }


  async onInit(): Promise<void> {
    this.countries = await this.getListOf(this.countryService, 'data');
  }

  initForm(): void {
    this.mainForm = this.formBuilder.group({
      image: new FormControl(null),
      name: new FormControl('', Validators.required),
      type: new FormControl(this.orgaTypes[0]),
      description: new FormControl(''),
      place: this.formBuilder.array([]),
      contactInfo: this.formBuilder.group({
        email: new FormControl(this.user.email, [Validators.required, Validators.email]),
        phone: new FormControl('', Validators.required),
        webSite: new FormControl(''),
        facebook: new FormControl(''),
        instagram: new FormControl(''),
        twitter: new FormControl('')
      }),
      billingInfo: this.formBuilder.group({
        siret: new FormControl(''),
        licenceNumber: new FormControl(''),
        vatNumber: new FormControl('')
      })
    })
    this.addPlace();
  }

  createPlace() {
    return this.formBuilder.group({
      name: new FormControl(''),
      address: new FormControl('', Validators.required),
      address2: new FormControl(''),
      zipcode: new FormControl('', Validators.required),
      city: new FormControl('', Validators.required),
      countryId: new FormControl('')
    })
  }

  addPlace() {
    const places = this.mainForm.get('place') as FormArray;
    places.push(this.createPlace());
  }

  removePlace(i: number) {
    const places = this.mainForm.get('place') as FormArray;
    places.removeAt(i);
  }

  setDescriptionValue(value) {
    this.mainForm.patchValue({ description: value });
  }

  async submitForm() {
    const formData = this.mainForm.value;;
    this.getFileToCreate(formData.image, 'imageId');
    this.createMediaAndUpdate(formData, 'organisationId', ['name', 'type', 'place'], async (res: APICreateResponse | APIResponse) => {
      this.goToOrganisation((res as APICreateResponse)._id || this.data._id);
    }, null);
  }

  async goToOrganisation(id: string) {
    const organisationToken = await this.authService.authOrganisation(id).toPromise();
    this.formProvider.goToOrganisation(id, organisationToken);
  }

  public uploadFile(file: File): void {
    this.setValue('image', file);
  }

  goBack() {
    this.router.navigateByUrl('/organisations')
  }


}
