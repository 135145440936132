<section id="form_page" class="container loading_container" [ngClass]="{ 'loaded' : mainForm && data }">
  <div class="loading_content">
    <div id="page_header">
      <h1>Paramètres</h1>
    </div>
    <div id="form_wrapper">
      <h2>Votre organisation</h2>
      <form *ngIf="mainForm" [formGroup]="mainForm" class="large">
        <h3>Votre organisation</h3>
          <p class="error" *ngIf="errorLoadingImage">
              Un problème est survenu lors du chargement de votre image. Veuillez en ajouter une à nouveau.
          </p>
          <app-file-input *ngIf="loaded" [loading]="loadingImage.includes('imageId')"
          class="form_item" text="Ajoutez l'image de présentation de votre événement"
          [preFile]="data && !loadingImage.includes('imageId') && data.image"
          (imageChange)="changeImage('imageId', 'imageId', $event)"
          [cropperStaticHeight]="370" [cropperStaticWidth]="370"></app-file-input>

        <div class="form_cols">
          <div class="form_item half">
            <label for="name">Nom de l'organisation</label>
            <input id="name" type="text" formControlName="name">
          </div>
          <div class="form_item half">
            <label for="type">Type</label>
            <div class="input_wrap select">
              <select id="type" formControlName="type">
                <option *ngFor="let type of orgaTypes" value="{{type}}">{{type}}</option>
              </select>
              <svg role="img" title="chevron" class="icon-chevron">
                <use href="assets/images/sprite.svg#icon-chevron"></use>
              </svg>
            </div>
          </div>
        </div>
        <div class="form_item">
          <label for="description">Description</label>
          <app-wysiwyg [previousValue]="data.description" (changeText)="setDescriptionValue($event)">
          </app-wysiwyg>
        </div>
        <h3>L'adresse de votre organisation</h3>
        <div formArrayName="place">
          <div *ngFor="let element of mainForm.get('place').controls; index as i ">
            <div [formGroupName]="i">
              <span (click)="removePlace(i)" class="group_title" *ngIf="i>0">
                <i>
                    <svg role="img" title="add" class="icon-add">
                        <use href="assets/images/sprite.svg#icon-add"></use>
                    </svg>
                </i>
                Adresse {{i + 1}}</span>
              <div class="form_item">
                <label for="name{{i}}">Nom de l'adresse</label>
                <input id="name{{i}}" type="text" formControlName="name" placeholder="Par exemple, Siège social...">
              </div>
              <div class="form_item">
                <label for="address{{i}}">Adresse de l'organisation</label>
                <input id="address{{i}}" type="text" formControlName="address">
              </div>
              <div class="form_item">
                <label for="address2{{i}}">Adresse bis</label>
                <input id="address2{{i}}" type="text" formControlName="address2">
              </div>
              <div class="form_cols">
                <div class="form_item quarter">
                  <label for="zipcode{{i}}">Code Postal</label>
                  <input id="zipcode{{i}}" type="text" formControlName="zipcode">
                </div>
                <div class="form_item three_cols">
                  <label for="city{{i}}">Ville</label>
                  <input id="city{{i}}" type="text" formControlName="city">
                </div>
                <div class="form_item three_cols">
                  <label for="countries{{i}}">Pays</label>
                  <div class="input_wrap select">
                    <select id="countries{{i}}" formControlName="countryId">
                      <option *ngFor="let country of countries" value="{{country._id}}">
                        {{country.name}}</option>
                    </select>
                    <svg role="img" title="chevron" class="icon-chevron">
                      <use href="assets/images/sprite.svg#icon-chevron"></use>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <app-button class="add_group" [style]="'link'" text="Ajouter une autre adresse" [loadingFunction]="null" (clickEvent)="addPlace()">
        </app-button>
        <div formGroupName="contactInfo">
          <h3>Vos informations de contact</h3>
          <div class="form_item half">
            <label for="email">E-mail</label>
            <input id="email" type="email" formControlName="email">
          </div>
          <div class="form_cols">
            <div class="form_item half">
              <label for="phone">Numéro de téléphone</label>
              <input id="phone" type="tel" formControlName="phone">
            </div>
            <div class="form_item half">
              <label for="website">Site internet</label>
              <input id="website" type="text" formControlName="webSite">
            </div>
          </div>
          <h3>Vos réseaux sociaux</h3>
          <div class="form_cols">
            <div class="form_item half">
              <label for="facebook">Page Facebook</label>
              <input id="facebook" type="text" formControlName="facebook">
            </div>
            <div class="form_item half">
              <label for="instagram">Compte Instagram</label>
              <input id="instagram" type="text" formControlName="instagram">
            </div>
            <div class="form_item half">
              <label for="twitter">Compte Twitter</label>
              <input id="twitter" type="text" formControlName="twitter">
            </div>
          </div>
        </div>
        <div formGroupName="billingInfo">
          <h3>Vos informations de facturation</h3>
          <div class="form_cols">
            <div class="form_item three_cols">
              <label for="siret">Siret</label>
              <input id="siret" type="text" formControlName="siret">
            </div>
            <div class="form_item quarter">
              <label for="licence-number">N° de licence</label>
              <input id="licence-number" type="text" formControlName="licenceNumber">
            </div>
            <div class="form_item three_cols">
              <label for="vat-number">N° de TVA intracommunautaire</label>
              <input id="vat-number" type="text" formControlName="vatNumber">
            </div>
          </div>
        </div>
        <div class="form_actions right">
          <app-button [loadingOff]="loadingState$" (clickEvent)="cancel()" text="Annuler" [style]="'border blue'"></app-button>
          <app-button [loadingOff]="loadingState$" type="submit" [style]="'plain'" [disabled]="mainForm.invalid"
            (clickEvent)="submitForm()" text="Valider"></app-button>
            <app-popup *ngIf="error != null" title="Une erreur est survenue" message="{{error}}" popupOpened="true"
            (backEvent)="error = null"></app-popup>
        </div>
      </form>
    </div>
  </div>
</section>
