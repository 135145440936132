import { Injectable } from '@angular/core';
import { Provider } from '../provider';
import { ITicket } from 'src/app/models/ticket.model';
import { APIResponse, GetParams } from 'src/app/models/type.definition';
import { Observable } from 'rxjs';
import { HTTPTools } from 'src/app/etc/http-tools';

@Injectable()
export class TicketService extends Provider<ITicket>{
  protected url = "events/tickets";
  public exportCSV(eventId: string, body?: GetParams<ITicket>): Observable<Blob> {
    return this.httpClient.get(this.getUrl(`list/export/${eventId}`, body), { responseType: 'blob' });
  }

  public getSoldCounts(eventId: string): Observable<{ id: string, sold: number }[]> {
    return this.httpClient.get<{ id: string, sold: number }[]>(this.getUrl(`/get-sold-counts/${eventId}`))
  }

  public resendTicket(id: string): Observable<APIResponse> {
    return this.httpClient.get<APIResponse>(this.getUrl(`resend-ticket/${id}`));
  }

  public streamOrder(id: string, token: string, type: 'orderId' | 'ticketId' | 'orderIdTickets' = 'orderId', template: 'ticket' | 'badge' = 'ticket'): Observable<Blob> {
    const baseUrl = this.url;
    this.url =  "events/orders"
    HTTPTools.setNextContentType('application/pdf');
    const stream = this.httpClient.get(this.getUrl(`stream/${type}/${id}/${token}`, {pdfTemplate: template}), { responseType: 'blob' });
    this.url = baseUrl;
    return stream;
  }

}
